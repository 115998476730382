.newsroom-hero {
    margin-bottom: 40px;
    margin-top: calc(20vh - 70px);

    @include media-query(tablet-landscape) {
        margin-top: calc(20vh - 50px);
    }

    @include media-query(desktop-wide) {
        margin-top: calc(20vh - 70px);
    }

    &__heading {
        font-size: 50px;
        line-height: 50px;

        @include media-query(tablet-portrait) {
            font-size: 70px;
            letter-spacing: -0.04em;
            line-height: 80px;
        }

        @include media-query(desktop) {
            font-size: 80px;
            letter-spacing: -0.04em;
            line-height: 90px;
        }
    }

    // correct spacing on person snippet when nested
    .sf-section {
        margin-top: 30px;
        padding: 0 30px 15px 0;
        width: 300px;

        @include media-query(tablet-portrait) {
            margin-top: 0;
        }
    }
}
