/*
------------------------------------*\
	$BASE DEFAULTS
\*------------------------------------
*/

// Box Sizing
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Prevent text size change on orientation change.
// sass-lint:disable no-vendor-prefixes
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-family: $font--secondary;
}

body {
  background-color: $color--white;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  &.lg-on,
  &.no-scroll {
    // prevent scroll on iOS
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
  }

  &.no-scroll {
    height: 100vh;
    position: fixed;
    top: 0;
  }
}

html,
body {
  height: 100%;
  width: 100%;
}

// Prevent empty space below images appearing
img,
svg,
video {
  vertical-align: top;
}

// Responsive images
img {
  height: auto;
  max-width: 100%;

  &.full-width {
    width: 100%;
  }
}

// sass-lint:disable single-line-per-selector
button,
input,
select,
textarea {
  font-family: inherit;
}
// sass-lint:enddisable

a {
  color: $color--dark-grey;
  font-weight: $weight--normal;
  text-decoration: none;

  &:focus,
  &:hover {
    color: $color--purple;
    cursor: pointer;
  }
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.logo-text {
  fill: $color--dark-grey;
}

.main-container {
  flex: 1 0 auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
