.responsive-object {
    position: relative;

    iframe,
    object,
    embed {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
