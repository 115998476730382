.sf-section-teaser {
  padding-top: 30px;
  position: relative;

  @include media-query(tablet-portrait) {
    padding-top: 110px;
  }

  @include media-query(tablet-landscape) {
    display: flex;
  }

  &::before {
    @include header-slash;
  }

  &__heading {
    font-size: 40px;
    letter-spacing: -0.02em;
    line-height: 1;
    margin-bottom: 30px;

    @include media-query(tablet-landscape) {
      font-size: 50px;
      margin-bottom: 0;
      padding-right: 40px;
      width: 50%;
    }

    @include media-query(desktop) {
      font-size: 70px;
    }
  }

  &__content {
    max-width: 640px;

    @include media-query(tablet-landscape) {
      padding-left: 40px;
      width: 50%;
    }

    @include media-query(desktop) {
      max-width: 680px;
    }

    p {
      @include font-size(delta);

      color: $color--dark-grey;
      font-family: $font--primary;
      font-weight: $weight--light;
      line-height: 1.5;
      margin: 10px 0;

      @include media-query(tablet-landscape) {
        @include font-size(gamma);
      }
    }
  }
}
