.cookie-notification {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    color: $color--dark-grey;
    box-shadow: 0 -1px 3px $shadow--pale;
    -moz-box-shadow: 0 -1px 3px $shadow--pale;
    -webkit-box-shadow: 0 -1px 3px $shadow--pale;
    z-index: 25;
    font-family: $font--primary;
    font-size: 17px;

    @media (max-width: $sw-tablet-portrait) {
        bottom: unset;
        top: 0;
    }

    &__inner {
        margin: 0 auto;
        max-width: 1000px;
        padding: 20px;
    }

    &__header {
        margin-top: 12px;
        font-style: italic;
    }

    &__buttons {
        margin-top: 10px;
        text-align: right;
    }

    &__button {
        background-color: $color--blue;
        border: none;
        color: white;
        -webkit-text-fill-color: white;
        margin-right: 12px;
        border-radius: 3px;
        padding: 10px 16px;
        font-family: $font--primary;
        font-weight: 700;
        cursor: pointer;
    }

    &__link {
        @include link-border;
    }
}
