// sass-lint:disable-all

/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * cca.edu
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2016
 */

@font-face {
    font-family: "lineto";
    src: url("~fonts/lineto-brown-light.eot");
    src:
        url("~fonts/lineto-brown-light.eot#iefix") format("embedded-opentype"),
        url("~fonts/lineto-brown-light.woff2") format("woff2"),
        url("~fonts/lineto-brown-light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "lineto";
    src: url("~fonts/lineto-brown-lightitalic.eot");
    src:
        url("~fonts/lineto-brown-lightitalic.eot#iefix") format("embedded-opentype"),
        url("~fonts/lineto-brown-lightitalic.woff2") format("woff2"),
        url("~fonts/lineto-brown-lightitalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "lineto";
    src: url("~fonts/lineto-brown-regular.eot");
    src:
        url("~fonts/lineto-brown-regular.eot#iefix") format("embedded-opentype"),
        url("~fonts/lineto-brown-regular.woff2") format("woff2"),
        url("~fonts/lineto-brown-regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "lineto";
    src: url("~fonts/lineto-brown-italic.eot");
    src:
        url("~fonts/lineto-brown-italic.eot#iefix") format("embedded-opentype"),
        url("~fonts/lineto-brown-italic.woff2") format("woff2"),
        url("~fonts/lineto-brown-italic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "lineto";
    src: url("~fonts/lineto-brown-bold.eot");
    src:
        url("~fonts/lineto-brown-bold.eot#iefix") format("embedded-opentype"),
        url("~fonts/lineto-brown-bold.woff2") format("woff2"),
        url("~fonts/lineto-brown-bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "lineto";
    src: url("~fonts/lineto-brown-bolditalic.eot");
    src:
        url("~fonts/lineto-brown-bolditalic.eot#iefix") format("embedded-opentype"),
        url("~fonts/lineto-brown-bolditalic.woff2") format("woff2"),
        url("~fonts/lineto-brown-bolditalic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}

/* Merriweather */

@font-face {
    font-family: 'merriweather';
    src:
        url('~fonts/merriweather_light-webfont.woff2') format('woff2'),
        url('~fonts/merriweather_light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'merriweather';
    src:
        url('~fonts/merriweather-lightit-webfont.woff2') format('woff2'),
        url('~fonts/merriweather-lightit-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'merriweather';
    src:
        url('~fonts/merriweather-regular-webfont.woff2') format('woff2'),
        url('~fonts/merriweather-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'merriweather';
    src:
        url('~fonts/merriweather-italic-webfont.woff2') format('woff2'),
        url('~fonts/merriweather-italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'merriweather';
    src:
        url('~fonts/merriweather-bold-webfont.woff2') format('woff2'),
        url('~fonts/merriweather-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'merriweather';
    src:
        url('~fonts/merriweather-boldit-webfont.woff2') format('woff2'),
        url('~fonts/merriweather-boldit-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'merriweather';
    src:
        url('~fonts/merriweather_ultrabold-webfont.woff2') format('woff2'),
        url('~fonts/merriweather_ultrabold-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'merriweather';
    src:
        url('~fonts/merriweather-ultrabdit-webfont.woff2') format('woff2'),
        url('~fonts/merriweather-ultrabdit-webfont.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'roboto-mono';
    src: url('~fonts/roboto-mono-700.woff') format('woff');
    src: url('~fonts/roboto-mono-700.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-mono';
    src: url('~fonts/roboto-mono-500.woff') format('woff');
    src: url('~fonts/roboto-mono-500.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-mono';
    src: url('~fonts/roboto-mono-regular.woff') format('woff');
    src: url('~fonts/roboto-mono-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'chimera-display';
    src: url('~fonts/chimeradisplay-regular.woff') format('woff');
    src: url('~fonts/chimeradisplay-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
