.pagination {
    &__list {
        display: flex;
        margin: 10px 0;
    }

    &__list-item {
        @include font-size(milli);

        font-family: $font--primary;
        margin: 0 3px;
        min-width: 30px;
        text-align: center;

        > a {
            display: block;
            height: 100%;
            padding: 3px 0;
            transition: background-color, color, $transition;
        }

        &--default {
            > a {
                border: 1px solid $color--dark-grey;

                &:hover {
                    background-color: $color--dark-grey;
                    color: $color--white;
                }
            }
        }

        &--active {
            > a {
                background-color: $color--dark-grey;
                color: $color--white;
            }
        }

        &--previous,
        &--next {
            > a {
                background-color: $color--teal;
                border: 1px solid $color--teal;

                &:hover {
                    background-color: $color--dark-grey;
                    border-color: $color--dark-grey;
                }
            }

            svg {
                fill: $color--white;
                height: 15px;
                margin-top: 3px;
                width: 15px;
            }
        }

        &--previous {
            svg {
                transform: rotate(180deg);
            }
        }

        &--dots {
            font-weight: $weight--bold;
            min-width: 15px;
            padding-top: 5px;
        }

        &--disabled {
            opacity: 0.25;
            position: relative;

            &::after {
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }
}
