.curriculum {
  position: relative;

  &__course-name {
    font-weight: $weight--normal;
    line-height: 1.3;

    @include media-query(tablet-landscape) {
      font-size: 24px;
    }
  }

  &__list {
    margin-bottom: 50px;
  }

  &__heading {
    color: $color--navy;
    font-size: 18px;
    font-weight: $weight--normal;
    line-height: 1.3;
    margin: 30px 0 5px;

    @include media-query(tablet-landscape) {
      font-size: 20px;
    }
  }

  &__course {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-family: $font--primary;
    line-height: 1.7;
    margin: 0;
  }

  &__title {
    padding-right: 20px;
    width: 70%;
  }

  &__unit {
    font-size: 12px;
    margin: 0;
    text-align: right;
    text-transform: uppercase;
    width: 30%;
  }

  &__total {
    display: flex;
    font-family: $font--primary;
    font-size: 14px;
    justify-content: flex-end;
    margin: 30px 0;
    text-transform: uppercase;

    p {
      border-top: 1px solid $color--default;
      margin: 20px 0 0;
      padding: 20px 0 0;

      span {
        font-weight: $weight--bold;
      }
    }
  }
}
