.nav {
  $root: &;

  align-items: center;
  display: flex;
  flex-direction: column;

  @include media-query(tablet-landscape) {
    flex-direction: row;
  }

  &--primary {
    @include media-query(tablet-landscape) {
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 0;
    }

    .nav__link {
      transition: $transition;

      &--active {
        color: $color--navy;
      }

      &--hidden {
        visibility: hidden;
      }

      &.active {
        svg {
          fill: $color--navy;

          &:first-of-type {
            display: none;
          }

          &:last-of-type {
            display: block;
          }
        }
      }

      &:hover {
        color: $color--purple;
      }

      &--search {
        &:hover {
          svg {
            fill: $color--purple;
          }
        }
      }
    }
  }

  &--secondary {
    margin-right: 15px;
  }

  &--tertiary {
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;

    a {
      font-size: 18px;
    }

    li:first-child {
      a {
        margin-left: 0;
      }
    }
  }

  &--breadcrumb {
    flex-direction: row;
    margin-bottom: 10px;
  }

  &__item {
    margin-bottom: 10px;
    padding-bottom: 10px;
    text-align: center;
    width: 60%;

    @include media-query(tablet-landscape) {
      margin: 0;
      padding: 0;
      width: auto;
    }

    .nav--primary & {
      color: $color--dark-grey;
      display: block;
      font-family: $font--primary;
      font-size: 18px;
      margin: 0;
      padding: 15px 0;

      @include media-query(tablet-landscape) {
        padding: 0;
      }

      // last item that isn't an icon
      &:nth-last-child(2) {
        #{$root}__link {
          margin-right: 0;
        }
      }
    }

    .nav--tertiary & {
      border-bottom: 0;
      line-height: 1.3;
      margin-bottom: 20px;
      padding-bottom: 0;
      text-align: left;
      width: 100%;
    }

    &--secondary {
      font-weight: $weight--light;
    }

    &--footer {
      a {
        border-bottom: 1px solid $color--teal;
        color: $color--white;
        font-family: $font--primary;
        font-weight: $weight--light;
        transition: none;

        &:hover {
          color: $color--teal;
        }
      }
    }

    &--footer-column {
      &::after {
        display: none;
      }

      a {
        color: $color--white;
        margin: 0;
      }
    }

    &--back {
      @include font-size(milli);

      text-transform: uppercase;
    }

    &--breadcrumb {
      border-bottom: 0;
      font-size: 12px;
      font-weight: $weight--light;
      margin: 0 5px 0 0;
      padding: 0;
      text-transform: uppercase;
      width: auto;

      &::after {
        color: $color--primary;
        content: '›';
      }

      &:first-child {
        a {
          margin-left: 0;
        }
      }

      &:last-child {
        border-bottom: 1px solid;
        margin-left: 10px;
      }
    }
  }

  &__link {
    border-bottom: 1px solid transparent;
    color: $color--dark-grey;
    transition: color, border $transition;

    &:hover {
      cursor: pointer;
    }

    @include media-query(tablet-landscape) {
      align-items: center;
      color: $color--dark-grey;
      display: flex;
      font-family: $font--primary;
      font-size: 15px;
      margin-right: 20px;
      padding: 15px;
    }

    &--breadcrumb {
      color: $color--primary;

      &:hover {
        border-bottom: 1px solid $color--primary;
        color: lighten($color--primary, 20%);
      }
    }

    &--search {
      background: none;
      border: 0;

      @include media-query(tablet-landscape) {
        height: 70px;
        margin: 0;
        padding: 0;
        width: 65px;
      }

      span {
        display: none;
      }

      svg {
        fill: $color--dark-grey;
        height: 20px;
        margin: 0 auto;
        transition: $transition;
        width: 20px;

        &:last-of-type {
          display: none;
        }
      }
    }
  }

  &__icon {
    @include media-query(tablet-landscape) {
      display: none;
    }
  }
}
