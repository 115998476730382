.button-block {
    &__button {
        background-color: $color--teal;
        padding: 5px 14px;
        border: none;
        color: $color--dark-grey;
        text-align: center;
        text-decoration: none !important;
        font-size: 14px;
        font-weight: 400;
        font-family: "lineto", arial, sans-serif;
        display: inline-block;
        min-width: 120px;
        transition: 0.3s ease-out;

        &:hover {
            background-color: $color--purple;
            color: $color--white;
        }
    }
}
