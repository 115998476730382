.react-gallery-masonry {
  display: flex;
  padding: 80px 0;
  width: auto;

  @include media-query(tablet-portrait) {
    margin: 0 30px;
  }

  @include media-query(tablet-landscape) {
    margin: 0 80px;
  }

  @include media-query(desktop) {
    margin: 0 140px;
  }

  &__column {
    background-clip: padding-box;
  }

  &__arrow-button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $color--purple;

      .react-gallery-masonry__arrow-icon {
        background-color: $color--white;
      }
    }
  }

  &__arrow-icon {
    @include icon;

    background-color: $color--black;
    width: 16px;
    height: 32px;

    &--next {
      @include arrow-right-icon;
    }

    &--prev {
      @include arrow-left-icon;
    }

    &--down {
      @include arrow-down-icon;
    }
  }

  &__toggle-caption {
    display: none;
    width: 36px !important;
    background-color: unset;
  }
}

.react-lg--masonry {
  .lg-backdrop {
    background-color: $color--white;
  }

  .lg-sub-html {
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    color: $color--black;
    padding: 0 !important;
    margin: 0 !important;
    background-image: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    min-height: unset;
    max-height: 100%;
    bottom: calc(59px + 1em) !important;


    .react-gallery-masonry__toggle-caption {
      display: flex;
      box-shadow: none;
      border: none;
      cursor: pointer;
      margin-bottom: 12px;
      margin-right: 30px;

      @include media-query(tablet-portrait) {
        margin-right: 0;
      }
    }

    .react-gallery-item__caption {
      background-color: rgba(255, 255, 255, 0.9);
      padding: 12px 20px;
      max-height: 800px;
    }

    &.caption--hide {
      .react-gallery-item__caption {
        overflow: hidden;
        max-height: 0;
        padding: 0;
      }

      .react-gallery-masonry__toggle-caption {
        transform: rotate(0.5turn);
      }
    }

    p {
      font-family: $font--secondary;
    }

    @include media-query(tablet-portrait) {
      max-width: 33%;
      padding: 30px;
      right: 45px;
    }
  }

  .lg-content {
    bottom: calc(60px + 1em) !important;
    @include media-query(tablet-portrait) {
      padding-top: 60px;
    }
  }

  .lg-img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 40px;

    @include media-query(tablet-portrait) {
      padding: 0 140px;
    }
  }

  .lg-image {
    padding-top: 30px;
  }

  .lg-counter {
    min-width: 6%;
    left: 47%;
    bottom: 30px;
    height: 1em;
    font-weight: $weight--light;
  }

  .lg-next,
  .lg-prev {
    bottom: unset;
    top: 50%;
  }

  .lg-close {
    width: 90px !important;

    &::after {
      @include close-button-icon;

      height: 20px;
      width: 90px;
    }

    &:hover {
      background-color: $color--purple !important;

      &::after {
        background-color: $color--white !important;
      }
    }
  }
}
