.instagram-feed-container {
  display: flex;
  flex-wrap: wrap;
}

.instagram-feed {
  $root: &;

  font-family: $font--primary;
  margin: 0 0 20px;
  width: 100%;

  &:hover {
    color: $color--dark-grey;

    #{$root}__image {
      > img {
        filter: saturate(0);
      }

      &::after {
        opacity: 1;
      }
    }

    #{$root}__text {
      opacity: 1;
    }
  }

  @include media-query(tablet-portrait) {
    margin-bottom: 40px;
    margin-right: 5%;
    width: 47.5%;
  }

  @include media-query(desktop) {
    margin-right: 4%;
    width: 22%;
  }

  &:nth-child(2n+2) {
    margin-right: 0;

    @include media-query(tablet-portrait) {
      margin-right: 0;
    }

    @include media-query(desktop) {
      margin-right: 4%;
    }
  }

  &:nth-child(4n+4) {
    @include media-query(tablet-portrait) {
      margin-right: 0;
    }
  }

  &:last-child {
    display: none;

    @include media-query(tablet-portrait) {
      display: block;
    }
  }

  &__image {
    position: relative;

    // Overlay
    &::after {
      @include teal-overlay;
    }

    > img {
      width: 100%;
    }
  }

  &__header {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  &__link {
    @include link-border;

    color: var(--black, $color--black);
    font-size: var( --font-size-body, 16px);
    display: inline-block;
    font-family: $font--primary;
    margin: 0 0 20px 35px;

    &:focus,
    &:hover {
      color: $color--purple;
    }
  }

  &__text {
    @include rem-font-size(17px);

    color: $color--white;
    height: 100%;
    left: 0;
    line-height: 1.4;
    opacity: 0;
    overflow: hidden;
    padding: 20px;
    position: absolute;
    top: 0;
    transition: opacity $transition;
    width: 100%;
    word-break: break-word;
    z-index: 3;

    > p {
      margin-top: 0;

      // sass-lint:disable no-vendor-prefixes
      @supports (-webkit-line-clamp: 2) {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        display: -webkit-box;
        overflow: hidden;

        @include media-query(desktop-extra-wide) {
          -webkit-line-clamp: 17;
        }
      }

      @supports not (-webkit-line-clamp: 2) {
        max-height: 120px;
        overflow: hidden;

        @include media-query(desktop-extra-wide) {
          max-height: auto;
        }
      }
    }
  }
}
