.gallery {
    &__close {
        display: none;
        height: 30px;
        position: absolute;
        right: 40px;
        top: 30px;
        width: 30px;

        &:hover {
            cursor: pointer;
            fill: $color--teal;
        }

        .gallery-open & {
            display: block;
        }
    }

    &__icon {
        fill: $color--teal;
        height: 30px;
        transform: translateX(0);
        transition: transform 0.4s ease-in;
        width: 20px;

        @include media-query(tablet-landscape) {
            height: 40px;

            &--next {
                &:hover {
                    transform: translateX(10px);
                }
            }
        }

        &--prev {
            transform: rotate(180deg);

            @include media-query(tablet-landscape) {
                &:hover {
                    transform: rotate(180deg) translateX(10px);
                }
            }
        }
    }
}
