.announcement-module {
  display: flex;
  height: 135px;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $color--sand;
  margin-top: -22px;
  z-index: 16;
  padding: 5px 40px;
  width: 100%;
  clip-path:
    polygon(
      0% 100%,
      0% 0%,
      85% 0%,
      85% 20px,
      100% 20px,
      100% 100%,
      15% 100%,
      15% 115px,
      0% 115px
    );

  @media (max-width: $sw-tablet-landscape) {
    height: unset;
    flex-direction: column;
    z-index: unset;
    align-items: flex-start;
    padding: 20px 40px 40px 40px;
    clip-path:
      polygon(
        85% 20px,
        85% 0%,
        0% 0%,
        0% 100%,
        100% 100%,
        100% 20px
      );
  }

  &__button {
    border: 3px solid $color--purple;
    margin: 20px;
    text-align: left;
    font-family: $font--primary;
    font-size: 18px;
    font-weight: $weight--bold;
    line-height: 38px;
    padding: 5px 20px;
    transition: $transition;
    cursor: pointer;

    @media (max-width: $sw-desktop-mid) {
      line-height: 1.5;
    }

    @media (max-width: $sw-tablet-landscape) {
      margin: 5px;
    }

    &--light-mode {
      border-color: $color--teal;
      background-color: $color--teal;
      color: $color--black;

      &:hover {
        background: $color--purple;
        border-color: $color--purple;
        color: $color--white;
      }
    }

    &--dark-mode {
      color: $color--black;
      background-color: $color--white;
      border-color: $color--black;

      &:hover {
        color: $color--white;
        background-color: $color--purple;
      }
    }
  }

  &__link-arrow {
    padding-left: 5px;
  }

  &--dark-mode {
    background-color: $color--black;
  }
}
