.related-topic {
  $root: &;

  &__link {
    color: $color--dark-grey;
    display: block;
    font-family: $font--primary;
    padding: 30px;
    position: relative;

    &::before,
    &::after {
      background-color: $color--dark-grey;
      content: "";
      left: 0;
      position: absolute;
      transition: $transition;
    }

    &::before {
      height: 1px;
      left: 10px;
      top: 0;
      width: 50px;
    }

    &::after {
      height: 50px;
      top: 10px;
      width: 1px;
    }

    &:hover {
      #{$root}__summary {
        color: $color--dark-grey;
      }

      &::before {
        width: 75px;
      }

      &::after {
        height: 75px;
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: $weight--bold;
    line-height: 1.2;
    margin: 0;
  }

  &__summary {
    /*
        We need this style to be doubled up
        because sometimes the summary is a richtext object and sometimes it is not.
      */
    font-size: 15px;
    font-weight: $weight--light;
    line-height: 1.4;
    margin: 5px 0 0;

    p {
      font-size: 15px;
      font-weight: $weight--light;
      line-height: 1.4;
      margin: 5px 0 0;
    }
  }
}
