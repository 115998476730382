.wavy-cta {
    min-height: 380px;
    padding: var(--size-layout-heading-spacing-notched, 130px) var(--size-layout-gutter, 140px);
    background-size: cover;
    background-position: center;
    position: relative;

    //notch
    &::before {
        background-color: var(--color-white);
        content: '';
        height: var(--size-notch);
        position: absolute;
        right: 0;
        top: 0;
        width: 65%;
    }

    &__buttons {
        display: grid;
        grid-gap: var(--size-40, 40px);
        grid-template-columns: 1fr 1fr;

        &--3 {
            grid-template-columns: 1fr 1fr 1fr;

            @media (max-width: $sw-tablet-landscape) {
                grid-template-columns: 1fr 1fr;

                >.wavy-cta__button-container:nth-child(3) {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
            }
        }

        &--4 {
            grid-template-columns: 1fr 1fr 1fr 1fr;

            @media (max-width: $sw-desktop) {
                grid-template-columns: 1fr 1fr;
            }
        }

        @media (max-width: $sw-tablet-portrait) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    &__button-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--size-40, 40px);
        align-self: stretch;
        height: fit-content;
        margin: auto 0;

        &:nth-of-type(even) {
            @media (max-width: $sw-tablet-portrait) {
                .wavy-cta__graphic {
                    right: unset;
                    left: -20px;
                }
            }
        }
    }

    &__button {
        all: unset;
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 40px 25px;
        box-sizing: border-box;

        @media (max-width: $sw-tablet-portrait) {
            padding: 40px 5px;
        }

        &:hover {
            .wavy-cta__button-graphic-svg {
                background-color: var(--purple, $color--purple);
            }
        }
    }

    &__link {
        position: relative;
        width: 100%;
    }

    &__button-text {
        display: inline-block;
        position: relative;
        color: var(--white, #FFF);
        font-family: $font--primary;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        text-align: center;
        text-transform: uppercase;
        z-index: 1;
    }

    &__button-graphic-svg {
        background-color: var(--black, #222);
        -webkit-mask-image: url("~images/wavy-button.svg");
        mask-image: url("~images/wavy-button.svg");
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-position: center;
        mask-size: 100% 100%;
        -webkit-mask-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__graphic {
        position: absolute;
        right: -20px;
        top: -25px;
        z-index: 1;
    }
}
