.search-panel {
  background-color: rgba(0, 191, 179, 0.95);
  height: 100vh;
  left: 0;
  opacity: 0;
  padding: 150px 0 20px;
  position: fixed;
  text-align: center;
  top: 70px;
  transform: translateY(-100%);
  transition: transform 0.25s ease-out, opacity 0.25s ease-out;
  width: 100%;
  z-index: 9;


  @include media-query(tablet-landscape) {
    padding-top: 200px;
  }

  &--active {
    z-index: 20;
    opacity: 1;
    transform: translateY(0);
  }

  &__form {
    margin: 0 auto;
    width: calc(100% - 40px);

    @include media-query(tablet-landscape) {
      width: calc(100% - 280px);
    }
  }

  &__label,
  &__submit {
    left: -1000px;
    position: fixed;
  }

  &__input-wrapper {
    align-items: center;
    border-bottom: 1px solid $color--white;
    display: flex;
  }

  &__input {
    background-color: transparent;
    background-image: linear-gradient(180deg, transparent 98%, #888B8D 0);
    background-repeat: no-repeat;
    background-size: 0 100%;
    border: 0;
    color: $color--white;
    display: block;
    flex: 1;
    font-family: $font--primary;
    font-size: 40px;
    font-weight: $weight--normal;
    line-height: 1;
    width: 100%;

    &::-ms-clear {
      // sass-lint:disable-line no-vendor-prefixes
      display: none;
    }

    &:focus {
      outline: 0;
    }

    @include media-query(tablet-landscape) {
      font-size: 70px;
      line-height: 1.4;
    }
  }

  &__clear {
    background: transparent;
    border: 0;
    color: $color--black;
    cursor: pointer;
    display: none;
    font-family: $font--primary;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    padding: 0;

    svg {
      fill: $color--black;
      height: 14px;
      vertical-align: -2px;
      width: 14px;
    }

    &:hover {
      color: $color--purple;

      svg {
        fill: $color--purple;
      }
    }
  }
}
