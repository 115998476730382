.grid {
    display: flex;
    flex-wrap: wrap;

    > * {
        flex-basis: calc(100% - 20px);
        margin: 10px;

        @media all and (min-width: 480px) {
            flex-basis: calc(50% - 20px);
        }

        @media all and (min-width: 1024px) {
            flex-basis: calc(30% - 20px);
        }
    }

    &--narrow {
        text-align: center;

        @include media-query(tablet-portrait) {
            text-align: left;
        }
    }
}

@supports (display: grid) {
    .grid {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(calc(50% - 20px), 1fr));
        margin: 30px 0;

        @include media-query(tablet-portrait) {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }

        > * {
            flex-basis: initial;
            margin: 0;
        }

        &--narrow {
            grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
            justify-content: center;
            text-align: center;

            @include media-query(tablet-portrait) {
                justify-content: flex-start;
                text-align: left;
            }
        }

        &--3-up {
            grid-gap: 50px;
            grid-template-columns: 1fr;
            margin: 0;

            @include media-query(tablet-portrait) {
                grid-gap: 7%;
                grid-template-columns: 1fr 1fr 1fr;
            }
        }

        &--2-up {
            grid-gap: 30px;
            grid-template-columns: 1fr;
            margin: 0;

            @include media-query(tablet-portrait) {
                grid-gap: 7%;
                grid-template-columns: 1fr 1fr;
            }
        }

        // use px grid gap instead of % to prevent grids with
        // more than six children overlapping the next el
        &--fixed-gap {
            @include media-query(tablet-portrait) {
                grid-gap: 90px;
            }
        }
    }
}
