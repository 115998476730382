.apply-quicklinks {
    position: absolute;
    background-color: $color--dark-grey;
    color: $color--white;
    z-index: 5;
    width: 300px;
    padding: 20px;

    @include media-query(tablet-landscape) {
        position: fixed;
        bottom: 20vh;
        left: 786px - 57px;
    }


    @include media-query(desktop) {
        left: 1024px - 57px;
    }

    &__notch {
        position: absolute;
        top: -20px;
        height: 20px;
        width: 243px;
        background-color: $color--dark-grey;
        left: 0;
        @include media-query(tablet-landscape) {
            right: 0;
            left: unset;
        }
    }

    &__title {
        font-size: 24px;
        line-height: 1.3;
        margin-bottom: 40px;
        @include media-query(tablet-landscape) {
            text-align: right;
        }
    }

    &__link-container {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 25px;
        }
    }

    &__link {
        border-bottom: 2px solid $color--teal;
        font-size: 18px;
        font-family: $font--primary;
    }

    a {
        color: $color--white;
    }
}
