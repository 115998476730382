/*
------------------------------------*\
    $TYPOGRAPHY
\*------------------------------------
*/

/* ============================================
    Base font
*/
html {
    color: $color--dark-grey;
    font-size: 15px;
    line-height: 1.7;

    @include media-query(desktop) {
        font-size: 16px;
    }
}

/* ============================================
    Families – one mixin per typeface
    :: For each font mixin defined here, make sure each property is negated (set
    :: to its default value, usually `inherit`) in all other font mixins.
*/
@mixin heading-text {
    font-family: $font--primary;
    font-style: inherit;
    font-weight: inherit;
}

@mixin body-text {
    font-family: $font--secondary;
    font-style: inherit;
    font-weight: $weight--light;
    text-transform: inherit;
}

@mixin quote-text {
    font-family: $font--secondary;
    font-style: italic;
    font-weight: inherit;
    text-transform: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 1rem;
}


h1,
h2,
h3,
h4,
h5,
h6,
.heading-text {
    @include heading-text;
}

html,
.body-text {
    @include body-text;
}

blockquote {
    @include quote-text;
}

// Default sizes
h1,
.alpha {
    font-size: 32px;
    font-weight: $weight--bold;
    line-height: 44px;

    @include media-query(tablet-portrait) {
        font-size: 50px;
        line-height: 60px;
    }

    @include media-query(desktop-wide) {
        font-size: 60px;
        line-height: 70px;
    }
}

h2,
.beta {
    font-size: 26px;
    font-weight: $weight--normal;
    line-height: 1.2;

    @include media-query(tablet-portrait) {
        font-size: 36px;
    }

    @include media-query(desktop-wide) {
        font-size: 38px;
    }
}

h3,
.gamma {
    @include font-size(delta);
}

h4,
.delta {
    @include font-size(epsilon);

    font-weight: $weight--bold;

    @include media-query(tablet-portrait) {
        @include font-size(delta);
    }
}

h5,
.epsilon {
    @include font-size(zeta);

    @include media-query(tablet-portrait) {
        @include font-size(epsilon);
    }
}

h6,
.zeta {
    @include font-size(zeta);
}

.giga { @include font-size(giga); }
.mega { @include font-size(mega); }
.kilo { @include font-size(kilo); }

small,
.milli { @include font-size(milli); }
