.sf-intro {
    color: $color--dark-grey-80;
    font-family: $font--primary;
    font-size: 20px;
    line-height: 1.5;
    margin: 0;
    position: relative;

    @include media-query(tablet-landscape) {
        font-size: 24px;
    }

    a {
        font-size: 20px;

        @include media-query(tablet-landscape) {
            font-size: 24px;
        }
    }
}
