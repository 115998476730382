/*
    $ANIMATION
*/

// Ease up/down
@keyframes ease-up-down {
    0% {
        transform: translate3d(0, 0, 0);
    }

    25% {
        transform: translate3d(0, 0, 0);
    }

    50% {
        transform: translate3d(0, 0, 0);
    }

    75% {
        transform: translate3d(0, 20px, 0);
    }
}

// Animate on Scroll Effects
.aos {
    opacity: 0;
    animation-duration: 3s;
    animation-fill-mode: both;
    -webkit-animation-duration: 3s;
    -webkit-animation-fill-mode: both;
}

.aos-fade-in {
    animation-name: fade-in-up;
    -webkit-animation-name: fade-in-up;
}

@keyframes fade-in-up {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
