.sf-media-with-text {
  $root: &;


  @include media-query(tablet-landscape) {
    display: flex;
  }

  &__media,
  &__text {
    @include media-query(tablet-landscape) {
      width: 50%;
    }
  }

  &__media {
    > img {
      width: 100%;
    }

    > video {
      max-width: 100%;
      max-height: 80vh;
    }
  }

  &__text {
    padding-top: 30px;

    @include media-query(tablet-landscape) {
      padding-top: 0;
    }

    .rich-text {
      h1,
      h2,
      h3,
      h4 {
        margin-top: 0;
      }
    }
  }

  &__media-fallback {
    background-color: $color--navy;
    height: 100%;
  }

  &__credit {
    color: $color--dark-grey;
    font-family: $font--primary;
    font-size: 14px;
    line-height: 1.3;
    margin: 5px 0 0;
    padding: 5px 20px 0 0;
    position: relative;
  }

  // Media | Text
  &--media-left {
    #{$root}__media {
      @include media-query(tablet-landscape) {
        padding-right: 40px;
      }
    }

    #{$root}__text {
      @include media-query(tablet-landscape) {
        padding-left: 40px;
      }
    }
  }

  // Text | Media
  &--media-right {
    flex-direction: row-reverse;

    #{$root}__media {
      @include media-query(tablet-landscape) {
        padding-left: 40px;
      }
    }

    #{$root}__text {
      @include media-query(tablet-landscape) {
        padding-right: 40px;
      }
    }
  }
}
