.card {
    background-color: $color--tertiary;
    padding: 20px;
    transition: background-color $transition;

    &:hover {
        background-color: darken($color--tertiary, 10%);
    }
}

.card-with-image {
    &:only-child {
        max-width: 260px;
    }
}
