.keydate {
    font-family: $font--primary;
    margin: 0 0 20px;
    max-width: 900px;
    padding: 15px 0;

    @include media-query(tablet-portrait) {
        display: flex;
        padding: 15px 0;
    }

    &__range {
        display: flex;
        padding: 4px 0 0;
        position: relative;
        right: 1px;

        @include media-query(tablet-portrait) {
            padding: 4px 0;
            width: 130px;
        }
    }

    &__end,
    &__start {
        display: flex;
        flex-direction: column;
    }

    &__month {
        font-size: 14px;
        font-weight: $weight--normal;
        line-height: 1.2;
        text-transform: uppercase;
    }

    &__date {
        font-size: 28px;
        line-height: 1.1;
        position: relative;
    }

    &__end {
        padding-left: 30px;
        position: relative;

        &::before {
            background-color: $color--dark-grey-30;
            content: '';
            height: 1px;
            left: 10px;
            position: absolute;
            top: 18px;
            width: 10px;
        }
    }

    &__meta {
        padding-top: 10px;
        width: 100%;

        @include media-query(tablet-portrait) {
            padding: 0 0 0 30px;
        }
    }

    &__title {
        font-size: 20px;
        font-weight: $weight--bold;
        line-height: 1.4;
        margin: 0 0 5px;
    }

    &__text {
        color: $color--dark-grey-80;
        font-size: 16px;
        font-weight: $weight--light;
        line-height: 1.5;
        margin: 0;
    }

    &--home {
        padding: 0;
        width: 100%;

        .keydate {
            max-width: unset;
        }

        &:not(:last-child){
            border-bottom: 1px solid #DFDFDF;
        }
    }
}
