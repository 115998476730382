.wrapper {
    &--small {
        margin: 0 auto;
        max-width: $wrapper--small;
    }

    &--large {
        margin: 0 auto;
        max-width: $site-width;
    }

    &--main {
        background-color: $color--white;
        padding: 20px;
    }

    &--flex {
        display: flex;
        flex-direction: column;

        @include media-query(tablet-portrait) {
            flex-direction: row;
        }
    }
}
