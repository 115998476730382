.splide {
  $root: &;

  &__track--fade {
    > #{ $root }__list {
      > #{ $root }__slide {
        margin: 0 !important;
        opacity: 0;
        z-index: 0;

        &.is-active {
          opacity: 1;
          z-index: 1;
        }
      }
    }
  }
}
