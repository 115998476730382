.section-heading {
  padding-top: 30px;
  position: relative;

  @include media-query(tablet-portrait) {
    padding-top: 110px;
  }

  &::before {
    @include header-slash;
  }

  &__title {
    color: $color--navy;
    font-size: 22px;
    font-weight: $weight--light;
    margin: 0 0 5px;

    @include media-query(tablet-portrait) {
      font-size: 26px;
    }
  }

  &__intro {
    font-weight: $weight--normal;
    line-height: 1.2;
    margin: 0;
  }
}
