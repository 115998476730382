.person-snippet {
  $root: &;

  display: flex;
  font-family: $font--primary;
  margin-top: 10px;
  word-break: break-word;

  &--spaced {
    padding-left: 100px;
  }

  &__media {
    height: 80px;
    width: 80px;
  }

  &__image {
    align-self: flex-start;
    margin-right: 20px;

    .newsroom-hero & {
      display: none;
    }
  }

  &__name {
    color: $color--navy;
    font-size: 18px;
    line-height: 1.3;
    margin: 0;

    a {
      color: $color--navy;
      font-weight: inherit;
      transition: $transition;

      &:hover {
        color: $color--purple;
      }
    }
  }

  &__position {
    color: $color--dark-grey-80;
    font-weight: $weight--light;
    margin: 0;
  }

  &__phone {
    font-weight: $weight--normal;
    margin: 0;
  }

  &__link {
    @include link-border;
  }
}
