.program-card-container {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 20px;
}

.program-card {
  $root: &;

  font-family: $font--primary;
  margin: 0 3% 40px 0;
  width: 48.5%;

  &:hover {
    color: $color--dark-grey;

    #{$root}__image {
      > img {
        filter: saturate(0);
      }

      &::after {
        opacity: 1;
      }
    }
  }

  .program-card-container--grid & {
    margin-bottom: 40px;
  }

  @include media-query(tablet-portrait) {
    width: 22.7%;
  }

  @include media-query(desktop) {
    margin-right: 4%;
    width: 22%;
  }

  &:nth-child(2n+2) {
    margin-right: 0;

    @include media-query(tablet-portrait) {
      margin-right: 3%;
    }

    @include media-query(desktop) {
      margin-right: 4%;
    }
  }

  &:nth-child(4n+4) {
    @include media-query(tablet-portrait) {
      margin-right: 0;
    }
  }

  &__image {
    margin-bottom: 10px;
    position: relative;

    // Overlay
    &::after {
      @include teal-overlay;
    }

    > img,
    video {
      width: 100%;
      height: auto;
    }
  }

  &__qualification {
    color: $color--navy;
    font-weight: $weight--light;
    line-height: 1.2;
    margin: 0;
  }

  &__title {
    font-size: 20px;
    font-weight: $weight--bold;
    line-height: 1.2;
    margin: 0;
  }
}
