// Select2 overrides
select {
    &.select2-hidden-accessible {
        // sass-lint:disable-block no-important no-vendor-prefixes
        -webkit-appearance: none;
        -webkit-clip-path: unset !important;
        appearance: none;
        background: url('~images/chevron-black.svg') 124px 16px no-repeat;
        background-color: #F1F1F1;
        background-size: 16px;
        border: 0 !important;
        border-radius: 0;
        clip: unset !important;
        clip-path: unset !important;
        display: inline-block;
        font-family: $font--primary;
        font-size: 18px;
        font-weight: $weight--normal;
        height: auto !important;
        overflow: visible !important;
        padding: 5px 40px 5px 10px !important;
        position: relative !important;
        width: 150px !important;

        @include media-query(tablet-landscape) {
            display: none;
        }
    }
}

.select2 {
    $root: &;

    display: none;
    font-family: $font--primary;

    @include media-query(tablet-landscape) {
        display: inline-block;
    }

    &-container {
        width: auto !important; //sass-lint:disable-line no-important
    }

    &-dropdown {
        border: 0;
        border-radius: 0;
        width: 200px !important; //sass-lint:disable-line no-important
    }

    &-container--default {
        #{$root}-selection--single {
            background: url('~images/chevron-black.svg') 124px 16px no-repeat;
            background-color: #F1F1F1;
            background-size: 16px;
            border: 0;
            border-radius: 0;
            font-size: 18px;
            font-weight: $weight--normal;
            height: auto;
            padding: 5px 40px 5px 10px;
            width: 150px;

            &:focus {
                outline: 0;
            }

            &:hover {
                background: url('~images/chevron-teal.svg') 124px 16px no-repeat;
                background-color: #F1F1F1;
                background-size: 16px;

                #{$root}-selection__rendered {
                    color: $color--teal;
                }
            }

            #{$root}-selection__rendered {
                padding-left: 0;
                padding-right: 25px;
            }

            #{$root}-selection__arrow {
                display: none;
            }
        }

        #{$root}-results {
            background-color: $color--teal;
            font-family: $font--primary;
            padding: 10px 0;

            &__option {
                color: $color--white;
                font-size: 18px;
                font-weight: $weight--normal;
                padding: 5px 20px;
                transition: color $transition;

                &--highlighted {
                    &[aria-selected] {
                        background-color: transparent;
                        color: $color--black;
                    }
                }

                &[aria-selected='true'] {
                    background-color: $color--teal;
                    color: $color--black;
                    opacity: 0.5;
                }
            }

            > #{$root}-results__options {
                max-height: 100%;
            }
        }
    }
}
