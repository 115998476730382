%container,
.container {
    padding: 0 20px;

    @include media-query(tablet-portrait) {
        padding: 0 80px;
    }

    @include media-query(tablet-landscape) {
        padding: 0 130px;
    }

    @include media-query(desktop) {
        padding: 0 140px;
    }

    @include media-query(desktop-wide) {
        padding: 0 200px;
    }

    &--curriculum {
        margin-bottom: 40px;
        padding-right: 0;
    }

    &--listing-items {
        margin-bottom: 40px;
    }

    &--404 {
        margin: 20px 0 0;

        @include media-query(tablet-portrait) {
            margin: 70px 0 0;
        }
    }

    &--table {
        padding: 0 0 0 20px;
    }
}
