.featured-journeys {
    display: flex;
    width: 100%;
    padding: var(--size-layout-section-spacing, 40px) var(--size-layout-gutter, 140px);
    flex-direction: column;
    align-items: center;
    gap: 10px;

    &__items {
        display: grid;
        gap: var(--size-layout-item-gap, 15px);
        width: 100%;

        &--3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &--4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        &--5 {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        @media (max-width: $sw-tablet-landscape) {
            grid-template-columns: unset;
            gap: var(--size-layout-item-gap, 15px);
        }
    }

    &__item {
        &:hover {
            .featured-journeys__button {
                color: var(--purple, $color--purple);
                border-color: var(--purple, $color--purple);
                cursor: pointer;
            }
        }
    }

    &__image {
        margin-bottom: var(--size-layout-item-gap, 15px);

        @media (max-width: $sw-tablet-landscape) {
            display: none;
        }
    }

    &__button {
        color: var(--blue, #005776);
        font-family: $font--primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        width: 100%;
        border: 1px solid var(--blue, #005776);
        box-shadow: none;
        padding: var(--size-10, 10px);
        text-align: center;
        background-color: unset;
        // clip corners
        clip-path:
            polygon(
                3px 0%,
                calc(100% - 3px) 0%,
                100% 3px,
                100% calc(100% - 3px),
                calc(100% - 3px) 100%,
                3px 100%,
                0% calc(100% - 3px),
                0% 3px
            );
        @media (max-width: $sw-tablet-landscape) {
            padding: var(--size-20, 20px) var(--size-10, 10px);
        }
    }
}
