.react-gallery-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;

  &__caption-container {
    position: absolute;
    bottom: 1px;
    height: 1px;
    overflow: hidden;
    width: 100%;
    z-index: 1;
  }

  &__caption {
    font-size: 0.8em;
    padding: 0 10px 10px 20px;

    p,
    h4 {
      font-size: 1em;
      margin: 0;
    }

    h4 {
      font-size: 1.2em;
    }
  }

  &__image {
    z-index: 2;
    width: 100%;
    padding: 20px;
    border: none;
    display: block;
    position: relative;
  }

  &__play-button {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    width: 0;
    height: 0;
    border-radius: 50%;

    @include media-query(tablet-landscape) {
      padding: 20px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% + 1.5px), -50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 8px;
      border-color: transparent transparent transparent #000;

      @include media-query(tablet-portrait) {
        border-width: 8px 0 8px 10px;
      }
    }
  }

  &:hover,
  &:focus,
  &:focus-within {
    transform: scale(1.3);
    z-index: 4;

    .react-gallery-item__image {
      padding: 20px;
    }

    .react-gallery-item__caption-container {
      z-index: 3 !important;
      transform: translateY(100%);
      display: block;
      height: auto;
    }
  }
}
