.video-hero {
  $root: &;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 100px;
  padding-bottom: calc(70vh - 70px);
  position: relative;

  @include media-query(tablet-portrait) {
    padding-bottom: calc(70vh - 60px);
  }

  @include media-query(tablet-landscape) {
    // override inline style

    // sass-lint:disable no-important
    background-image: none !important;
    // sass-lint:enddisable

    margin-bottom: 0;
    padding-bottom: 100px;
  }

  // fallback if poster is removed
  &--no-image {
    background-color: $color--sand;
    margin-bottom: 100px;
    min-height: 70vh;
    padding-bottom: 35px;

    @include media-query(tablet-portrait) {
      padding-bottom: 100px;
    }

    #{$root}__container {
      bottom: 0;

      @include media-query(tablet-portrait) {
        bottom: -70px;
      }
    }
  }

  &--info-divisions {
    padding-bottom: 0;
  }

  &__container {
    background-color: $color--teal;
    bottom: -45px;
    padding-top: 10px;
    position: absolute;

    @include media-query(tablet-portrait) {
      bottom: -60px;
      padding-top: 0;
    }

    @include media-query(tablet-landscape) {
      bottom: 35px;
    }

    &::before {
      background-color: $color--teal;
      content: "";
      height: 20px;
      left: 0;
      position: absolute;
      top: -20px;
      width: 75%;
    }

    .container {
      padding-right: 100px;
    }
  }

  &__heading {
    color: $color--black;
    font-size: 50px;
    font-weight: $weight--normal;

    @include media-query(tablet-portrait) {
      font-size: 70px;
      letter-spacing: -0.04em;
      line-height: 80px;
    }

    @include media-query(tablet-landscape) {
      padding-top: 10px;
    }

    @include media-query(desktop) {
      font-size: 80px;
      line-height: 90px;
    }

    &--info-divisions {
      display: block;
    }
  }

  &__video {
    display: none;

    // make poster fill container
    object-fit: cover;
    width: 100%;

    @include media-query(tablet-landscape) {
      display: block;
    }

    // fallback if poster is removed
    &--no-image {
      background-color: $color--sand;
      max-height: 80vh;
    }
  }

  &__caption {
    display: inline-block;
    left: 10px;
    margin: 0;
    position: absolute;
    top: -55px;
    z-index: 1;

    // hide caption when video appears
    @include media-query(tablet-landscape) {
      display: none;
    }
  }
}
