.journey-page-nav {
    position: relative;
    margin-bottom: var(--size-layout-section-spacing); // 40px

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 66%;
        height: var(--size-notch); // 20px
        background-color: var(--color-white);
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 66%;
        height: var(--size-notch); // 20px
        background-color: var(--color-white);
    }

    &__content {
        padding: var(--size-layout-large-spacing) var(--size-layout-gutter);
        // 110px 20-200px
    }

    &__items {
        width: 75%;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: var(--size-layout-item-gap); // 15px
        align-items: center;

        @include media-query(tablet-portrait) {
            flex-direction: row;
            align-items: stretch;
        }
    }

    &__link {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        padding: var(--size-10) var(--size-30);
        text-align: center;
        font-family: var(--font-family-label);
        font-size: var(--font-size-label);
        font-weight: var(--font-weight-label);
        line-height: var(--line-height-label);
        background-color: var(--color-teal);
        color: var(--color-black);
        transition: color 0.2s, background-color 0.2s;

        &:nth-child(1) {
            background-color: var(--color-lime);
        }

        &:nth-child(2) {
            background-color: var(--color-yellow);
        }

        &:nth-child(3) {
            background-color: var(--color-pink);
        }

        &:nth-child(4) {
            background-color: var(--color-orange);
        }

        &:hover, &:active, &:focus {
            color: var(--color-white);
            background-color: var(--color-purple);
        }
    }

    &__copy {
        margin: auto;
        padding: 0 var(--size-20);
        width: 100%;
        margin-bottom: var(--size-layout-section-spacing); // 40px
        border: 0;
        text-align: center;
        font-family: var(--font-family-quote);
        font-size: var(--font-size-quote);
        font-weight: var(--font-weight-quote);
        line-height: var(--line-height-quote);
        font-style: normal;

        @include media-query(tablet-portrait) {
            width: 50%;
            text-align: left;
        }

        // Split text background
        span {
            -webkit-box-decoration-break: clone;
            background-color: var(--color-white);
            box-shadow: -20px 0 0 5px var(--color-white), 20px 0 0 5px var(--color-white);
            position: relative;
        }
    }

    &__caption {
        color: var(--color-white);
        padding: var(--size-5) var(--size-10);
        margin: 0;
        width: 33%;
        font-size: var(--font-size-detail);
        font-family: var(--font-family-detail);
        font-weight: var(--font-weight-detail);
        line-height: var(--line-height-detail);
        position: absolute;
        bottom: 0;
        left: 0;

        .rich-text {
            margin: 0;
            padding: 0;
        }

        p {
            margin: 0;
            line-height: inherit;
        }

        a {
            color: inherit;
            font-weight: inherit;
            font-size: inherit;
        }
    }
}
