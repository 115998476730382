.home-subheading {
    display: flex;
    width: 100%;
    padding: var(--size-layout-heading-spacing, 110px) var(--size-layout-gutter, 140px) var(--size-layout-section-spacing, 40px) var(--size-layout-gutter, 140px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--size-layout-item-gap, 15px);

    &__heading {
        font-family: $font--chimera;
        color: $color--true-black;
        font-size: 70px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: 3px;
        text-transform: uppercase;

        @media (max-width: $sw-tablet-landscape) {
            font-size: 50px;
            padding: 0;
        }
    }

    &__intro {
        .rich-text {
            all: unset;
            p {
                margin: 0;
            }
        }

        font-family: $font--primary;
        color: var(--black #222);
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 48px; // 150%


        @media (max-width: $sw-tablet-landscape) {
            font-size: 20px;
        }
    }
}
