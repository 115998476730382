.sf-quote {
  background-color: $color--purple;
  background-position: center;
  background-size: cover;
  padding: 60px 0;
  position: relative;
  width: 100%;

  &::after {
    background-color: $color--white;
    bottom: 0;
    content: '';
    height: 20px;
    position: absolute;
    right: 0;
    width: 30%;
  }

  @include media-query(tablet-portrait) {
    padding: 120px 0;
  }

  @include media-query(desktop-wide) {
    padding: 10vw 0;
  }

  &--with-media {
    padding: 0 !important;
  }

  &__container {
    &--with-media {
      padding: 20px !important;

      @include media-query(tablet-portrait) {
        padding: 120px 80px !important;
      }

      @include media-query(tablet-landscape) {
        padding: 120px 130px !important;
      }

      @include media-query(desktop) {
        padding: 10vw 140px !important;
      }

      @include media-query(desktop-wide) {
        padding: 10vw 200px !important;
      }
    }
  }

  &__media {
    // make poster fill container
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
    position: absolute;
  }

  &__text {
    background: transparent;
    border: 0;
    font-family: $font--primary;
    font-size: 20px;
    font-style: normal;
    font-weight: $weight--normal;
    line-height: 1.8;
    margin: 0;
    padding: 0;
    width: 50%;

    @include media-query(tablet-portrait) {
      font-size: 32px;
      line-height: 1.7;
      padding: 0 20px;
    }

    // Split text background
    span {
      -webkit-box-decoration-break: clone;
      background-color: $color--white;
      box-shadow: -20px 0 0 5px $color--white, 20px 0 0 5px $color--white;
      line-height: 1.7;
      position: relative;

      // Opening quotation mark
      &::before {
        content: '“';
        left: -15px;
        position: absolute;
      }
    }
  }

  &__meta {
    display: inline-block;
    font-family: $font--primary;
    font-style: normal;
    font-weight: $weight--normal;
    line-height: 1.2;
    margin: 40px 0 25px -20px;

    @include media-query(tablet-portrait) {
      margin: 40px 0 25px;
    }
  }

  &__author,
  &__program {
    > span {
      background-color: $color--dark-grey;
      color: $color--white;
    }
  }

  &__author {
    > span {
      font-size: 18px;
      line-height: 1.6;
      padding: 12px 20px 6px;
      position: relative;

      &::before {
        background-color: $color--dark-grey;
        content: '';
        height: 4px;
        position: absolute;
        right: 0;
        top: -4px;
        width: 80%;
      }
    }
  }

  &__program {
    > span {
      font-size: 14px;
      padding: 8px 40px 8px 20px;
    }
  }

  &__caption {
    bottom: 10px;
    display: inline-block;
    left: 10px;
    margin: 0;
    position: absolute;
    z-index: 1;
  }
}
