.inquiry-form {
    display: grid;
    grid-template-columns: auto 460px;
    grid-gap: 80px;
    margin-bottom: 30px;

    @media (max-width: $sw-tablet-landscape) {
        grid-template-columns: auto;
    }

    /*  Streamblock Styles  */

    &__stream-content {
        .rich-text {
            margin: 0;
        }
    }

    /*  Form Styles  */
    &__form {
        border: 1px solid $color--med-grey;

        &--success {
            background-color: $color--beige-tint;
            border-color: $color--beige-tint;
        }
    }

    &__privacy {
        margin: 12px;

        a {
            border-bottom: 1px solid $color--teal;

            &:focus,
            &:hover {
                border-color: $color--purple;
                color: $color--purple;
            }
        }
    }

    &__save-button {
        border: none;
        box-shadow: none;
        background-color: $color--teal;
        color: $color--black;
        padding: 10px 40px;
        margin: 12px;
        font-family: $font--primary;
        font-weight: bolder;
        cursor: pointer;

        &--disabled {
            background-color: $color--secondary;
            color: $color--med-grey;
        }
    }

    // Success Message
    &__success-header {
        color: $color--teal;
        font-size: 26px;
        margin: 0 0 5px;
        font-weight: 300;
    }

    &__success-container {
        margin: 30px;
    }

    // Form Sections
    &__section {
        margin: 12px;

        &--phone {
            margin: 12px -12px;
            padding: 12px;
            background-color: $color--secondary;
        }
    }

    &__section-heading {
        padding: 6px 12px;
        font-size: 16px;
        background-color: $color--med-grey;
        font-family: $font--secondary;
    }

    &__section-subheading {
        font-weight: bolder;
        font-size: 1em;
        margin-bottom: 0;
    }

    &__field-group {
        &--name,
        &--birthday {
            display: grid;
        }

        &--name {
            grid-template-columns: 1fr 1fr;
        }

        &--birthday {
            grid-template-columns: 3fr 2fr 3fr;
        }
    }

    /*  Form Fields  */
    &__field {
        width: 100%;
        border: none;
        border-bottom: 1px solid $color--cool-grey-med;
        font-size: 14px;
        font-style: italic;
        padding-left: 12px;
        background: none;
    }

    &__field-container {
        width: 100%;
        margin: 12px 0;
    }

    &__label {
        display: block;
        font-family: $font--primary;
        font-weight: bolder;

        &--checkbox {
            display: inline;
            margin-left: 12px;

            @media (max-width: $sw-tablet-landscape) {
                margin-left: 6px;
            }
        }
    }

    // Select Fields
    &__select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("~images/chevron-black.svg");
        background-repeat: no-repeat;
        background-position: 95% 50%;
        background-size: 12px;
        color: $color--cool-grey-med;
    }

    &__select-option {
        font-style: normal;
        color: $color--black;
    }

    // Checkbox Fields
    &__checkbox-container {
        padding-left: 12px;
    }

    &__checkbox {
        float: left;
        height: 1.7em;
    }

    // Toggle Fields
    &__toggle {
        display: flex;
    }

    &__toggle-option {
        position: relative;

        input {
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 0;
            margin: 0;
            z-index: 1;
            height: 100%;
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            opacity: 0;
        }

        &:hover {
            background-color: $color--teal-tint;
            border-color: $color--teal-tint;
        }

        &.active {
            background-color: $color--teal;
            border-color: $color--teal;
        }

        &--is-mobile {
            border: 1px solid $color--black;
            background-color: $color--white;
            padding: 10px 40px;
            margin: 4px;
            color: $color--black;
            font-family: $font--primary;
            font-weight: bolder;

            &:hover {
                background-color: $color--teal-tint;
                border-color: $color--teal-tint;
            }

            &.active {
                background-color: $color--teal;
                border-color: $color--teal;
            }
        }
    }

    // Errors and Warnings

    &__warning-container {
        height: 1em;
        padding-left: 12px;
    }

    &__warning,
    &__error,
    &__submit-error {
        font-size: 12px;
        color: $color--red;
        font-family: $font--primary;
        font-weight: bolder;
        position: absolute;
    }

    &__submit-error {
        position: unset;
        display: block;
    }

    .rich-text {
        padding: 0;
        margin: unset;
    }
}
