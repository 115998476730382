.sf-image {
  &__caption {
    color: $color--dark-grey;
    font-family: $font--primary;
    font-size: 14px;
    line-height: 1.3;
    width: 100%;
  }

  img {
    width: 100%;
  }

  video {
    max-width: 100%;
    max-height: 90vh;

    @include media-query(tablet-landscape) {
      width: 50%;
      display: block;
      margin: 0 auto;
    }
  }

  &--portrait {
    @include media-query(tablet-landscape) {
      max-width: 50%;
      margin: 0 auto;
    }
  }
}
