.button {
    background-color: transparent;
    background-image: none;
    border: 0;
    box-shadow: none;

    &:hover {
        cursor: pointer;
    }
}

.button-menu-toggle {
    background: transparent;
    border: 0;
    display: block;
    margin-top: 5px;
    padding: 5px 13px;
    position: relative;
    text-align: center;
    width: 65px;
    z-index: 10;

    @include media-query(tablet-portrait) {
        padding: 5px 20px;
    }

    svg {
        fill: $color--dark-grey;
        height: 22px;
        margin: 0 auto;
        transition: $transition;
        width: 22px;

        &:last-of-type {
            display: none;
        }
    }

    &.active {
        svg {
            &:first-of-type {
                display: none;
            }

            &:last-of-type {
                display: block;
            }
        }
    }

    span {
        display: none;
    }

    &--active,
    &.active,
    &:hover {
        svg {
            fill: $color--teal;
        }
    }
}
