$embed-border-width: 8px;

.sf-video-embed {
    margin: $embed-border-width 0;
    position: relative;

    &::before,
    &::after {
        background-color: $color--dark-grey;
        content: '';
        position: absolute;
    }

    // Top border
    &::before {
        height: $embed-border-width;
        left: 0;
        margin: 0 auto;
        top: - $embed-border-width;
        width: 100%;
    }

    // Bottom border
    &::after {
        bottom: -#{$embed-border-width};
        height: $embed-border-width;
        left: 0;
        margin: 0 auto;
        width: 100%;
    }

    &__side-borders {
        height: 100%;
        position: relative;
        width: 100%;

        &::before,
        &::after {
            background-color: $color--dark-grey;
            content: '';
            position: absolute;
        }

        // Left border
        &::before {
            height: 100%;
            left: -#{$embed-border-width};
            margin: auto 0;
            top: 0;
            width: $embed-border-width;
        }

        // Right border
        &::after {
            height: 100%;
            margin: auto 0;
            right: -#{$embed-border-width};
            top: 0;
            width: $embed-border-width;
        }

        div {
            position: relative;
            overflow: hidden;
            padding-top: 56.25%;

            iframe {
                border: 0;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }
}
