.header-cta {
  display: none;
  min-width: 140px;
  position: absolute;
  right: 0;
  top: 70px;
  transition: transform 350ms cubic-bezier(0.24, 0.26, 0.2, 1) 0ms;
  z-index: 10;

  @include media-query(tablet-landscape) {
    display: block;
  }

  .headroom & {
    position: fixed;
    transition: top 0.2s ease-in;
  }

  &--fixed {
    position: fixed;
    top: 0;
  }

  &--hidden {
    transform: translateX(100%);
  }

  &__link {
    display: block;
    font-family: $font--primary;
    font-size: 14px;
    text-align: center;
    transition: $transition;

    &:nth-child(1) {
      background-color: $color--dark-grey;
      color: $color--teal;
      padding: 10px 30px;
    }

    &:nth-child(2) {
      background-color: $color--teal;
      color: $color--dark-grey;
      float: right;
      padding: 5px 20px;
      width: 85%;
    }

    &:hover {
      background-color: $color--purple;
      color: $color--white;
    }

    &--mobile {
      width: 50% !important;

      &:nth-child(2) {
        padding: 12px 30px;
      }
    }
  }
}

.header-cta-mobile {
  display: flex;
  position: fixed;
  top: 70px;
  transform: translateX(100%);
  transition: transform 250ms cubic-bezier(0.24, 0.26, 0.2, 1) 0ms;
  width: 100%;
  z-index: 101;

  &--visible {
    transform: translateX(0);
  }

  &__link {
    display: block;
    font-family: $font--primary;
    font-size: 14px;
    text-align: center;
    transition: $transition;

    &:nth-child(1) {
      background-color: $color--dark-grey;
      color: $color--teal;
    }

    &:nth-child(2) {
      background-color: $color--teal;
      color: $color--dark-grey;
    }

    &:hover {
      background-color: $color--purple;
      color: $color--white;
    }
  }
}
