.icon {
    fill: $color--white;
    height: 20px;
    transition: fill $transition;
    width: 20px;

    &:hover {
        fill: darken($color--white, 20%);
    }

    &--home {
        fill: $color--primary;
        height: 15px;
        margin-right: 5px;
        width: 15px;
    }

    &--footer-social {
        @include media-query(tablet-portrait) {
            margin-right: 10px;
        }
    }
}
