.newsroom-listing-items {
    &__inner {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        @include media-query(tablet-landscape) {
            flex-direction: row;
            justify-content: space-between;
        }

        // Override grid
        .sf-section--person-snippet {
            display: block;
        }
    }

    &__form {
        max-width: 350px;
        position: relative;
        width: 100%;
        margin-bottom: 40px;

        @include media-query(tablet-portrait) {
            display: flex;
            min-width: 550px;
            width: auto;
        }
    }

    &__input {
        border: 0;
        border-bottom: 1px solid #141414;
        color: $color--dark-grey;
        display: block;
        flex: 1;
        font-family: $font--primary;
        font-size: 18px;
        font-weight: $weight--normal;
        line-height: 1;
        margin: 30px 0 0;
        padding: 10px 60px 10px 0;
        width: 100%;

        @include media-query(tablet-portrait) {
            margin: 0 0 0 30px;
            padding: 0;
            width: auto;
        }

        &::-ms-clear { // sass-lint:disable-line no-vendor-prefixes
            display: none;
        }

        &:focus {
            outline: 0;
        }

        @include placeholder-text {
            color: #B9B9B9;
        }
    }

    &__clear {
        background: transparent;
        border: 0;
        bottom: 10px;
        color: $color--navy;
        cursor: pointer;
        display: none;
        font-family: $font--primary;
        font-size: 14px;
        font-weight: 500;
        padding: 0;
        position: absolute;
        right: 0;

        @include media-query(tablet-portrait) {
            bottom: 0;
            margin: auto 0;
            top: 0;
        }

        svg {
            fill: $color--navy;
            height: 14px;
            vertical-align: -2px;
            width: 14px;
        }

        &:hover {
            color: $color--purple;

            svg {
                fill: $color--purple;
            }
        }
    }
}
