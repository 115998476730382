.square-icon {
    &__icon-container {
        height: 20px;
        width: 20px;
        border-radius: 5px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;

        &--award,
        &--gift,
        &--grad-cap,
        &--people,
        &--person,
        &--puzzle-piece,
        &--scholarship,
        &--speaker {
            height: 75px;
            width: 75px;
        }

        &--gallery-see-more {
            width: 24px;
            height: 24px;
            margin-left: 8px;
        }

        &--gallery-close {
            width: 32px;
            height: 32px;
            margin-left: 10px;
        }

        &--external-link {
            margin-left: 4px;
        }

        &--pause, &--play {
            height: 16px;
            width: 16px;
        }

        &--instagram {
            height: 30px;
            width: 30px;
            margin-right: var(--size-5, 5px);
        }
    }

    &__icon {
        height: 75px;
        width: 75px;
        margin: 2px;
        background-color: $color--white;
        @include icon;

        &--award,
        &--gift,
        &--grad-cap,
        &--people,
        &--person,
        &--puzzle-piece,
        &--scholarship,
        &--speaker {
            margin: 8px;
        }

        &--award {
            @include award-icon;
        }

        &--gift {
            @include gift-icon;
        }

        &--grad-cap {
            @include grad-cap-icon;
        }

        &--people {
            @include people-icon;
        }

        &--person {
            @include person-icon;
        }

        &--puzzle-piece {
            @include puzzle-piece-icon;
        }

        &--scholarship {
            @include scholarship-icon;
        }

        &--speaker {
            @include speaker-icon;
        }

        &--gallery-see-more {
            @include arrow-down-icon;

            width: 16px;
            margin: 0;
        }

        &--gallery-close {
            @include X-icon;

            width: 32px;
            height: 32px;
        }

        &--external-link {
            height: 15px;
            width: 15px;
            opacity: 0.6;
            @include external-link-icon;
        }

        &--pause, &--play {
            height: 16px;
            width: 16px;
            background-color: $color--black;
        }

        &--pause {
            @include pause-icon;
        }

        &--play {
            @include play-icon;
        }

        &--instagram {
            @include instagram-icon;
            background-color: var(--true-black, $color--true-black);
        }
    }
}
