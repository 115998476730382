/* Ticker animation */
@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

@keyframes ticker {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}


.ticker-animation,
.ticker-animation--2 {
    white-space: nowrap;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    animation-play-state: running;

    &--paused * {
        animation-play-state: paused;
    }
}

.ticker-animation {
    &--reverse {
        -webkit-animation-direction: reverse;
        animation-direction: reverse;
    }
}

.ticker-wrap {
    display: flex;
    align-items: center;

    //  Animate slow to stop on hover
    --duration: 20s;

    transform: translateX(0);
    transition: calc(var(--duration) * 0.1) ease-out;

    &:hover {
        transform: translateX(-2%);

        .ticker-animation,
        .ticker-animation--2 {
            animation-play-state: paused;
        }
    }

    &--reverse {
        &:hover {
            transform: translateX(2%);
        }
    }
}


/* Ticker block */
.ticker {
    display: flex;
    align-items: center;

    &--mono {
        border-top: 1px solid $color--black;
        border-bottom: 1px solid $color--black;
    }

    &__row {
        width: fit-content;
        max-width: 100vw;
        overflow: hidden;
        text-underline-offset: 4px;

        &--multi {
            text-transform: uppercase;
            border-bottom: 1px solid $color--black;

            &:first-child {
                border-top: 1px solid $color--black;
            }
        }

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__link {
        display: inline-block;
        box-sizing: border-box;
        border: 1px solid $color--white;

        &--multi {
            padding: var(--size-layout-element-spacing, 25px);
        }

        &:hover {
            .ticker__item {
                text-decoration: underline;
            }
        }

        &:focus-visible {
            border: 1px solid $color--purple;
            border-radius: 10px;
            outline: 0;

            .ticker__item {
                text-decoration: underline;
            }
        }
    }

    &__item {
        display: inline-block;
        font-family: $font--primary;
        font-weight: $weight--light;
        font-size: var(--font-size-label);
        line-height: var(--line-height-label);

        &--mono {
            padding: var(--size-layout-element-spacing, 25px);
        }
    }
}

/*
Ticker Button
==============
On multi ticker, has a button and label that are both clickable. The icon is a child of the button.
On mono ticker, no label, and icon is button.
*/

@mixin ticker-button-hover {
    transition: 200ms ease-out;
    background-color: $color--purple;
    border-color: $color--purple;

    .square-icon__icon--pause,
    .square-icon__icon--play {
        transition: 200ms ease-out;
        background-color: $color--white;
    }
}

.ticker-button {
    display: flex;
    align-items: center;
    background-color: $color--white;
    border: none;
    padding: 0;

    &:hover,
    &:focus-visible {
        cursor: pointer;
    }

    &--mono {
        border-right: 1px solid $color--black;
    }

    &--multi {
        border: 1px solid $color--white;

        &:hover,
        &:focus-visible {
            .ticker-button__icon-container {
                @include ticker-button-hover;
            }
        }

        &:focus-visible {
            outline: 0;
            border: 1px solid $color--purple;
        }
    }

    &__container {
        z-index: 1;

        &--multi {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: var(--size-layout-element-spacing) var(--size-layout-gutter);
        }
    }

    &__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;

        @include media-query(tablet-portrait) {
            height: 50px;
            width: 50px;
        }

        &:hover,
        &:focus-visible {
            border: 1px solid $color--purple;
            outline: 0;

            @include ticker-button-hover;
        }

        &--multi {
            border: 1px solid $color--black;
        }
    }

    &__icon {
        &--hidden {
            display: none !important;
        }
    }

    &__label {
        font-family: $font--primary;
        margin: 0 var(--size-layout-item-gap);
        font-size: var(--font-size-detail);
        line-height: var(--line-height-detail);

        &--hidden {
            display: none !important;
        }
    }
}
