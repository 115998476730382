.sf-feature-1-up,
.sf-feature-3-up {
  &:hover {
    .sf-feature-1-up__title,
    .sf-feature-3-up__title {
      text-decoration-color: $color--purple;
    }

    .sf-feature-1-up__image,
    .sf-feature-3-up__image {
      > img, video {
        filter: saturate(0);
      }

      &::after {
        opacity: 1;
      }
    }
  }

  &__image {
    display: block;
    margin-bottom: 10px;
    position: relative;

    @include media-query(tablet-portrait) {
      width: 200px;
      height: 200px;
    }
    @include media-query(tablet-landscape) {
      width: 250px;
      height: 250px;
    }
    @include media-query(desktop) {
      width: 350px;
      height: 350px;
    }

    // Overlay
    &::after {
      @include teal-overlay;
    }

    > img {
      width: 100%;
      height: auto;
    }
  }

  &__media {
    width: 100%;
    object-fit: cover;
    height: 400px;

    @include media-query(tablet-portrait) {
      height: 200px;
    }
    @include media-query(tablet-landscape) {
      height: 250px;
    }
    @include media-query(desktop) {
      height: 350px;
    }
  }

  &__image-fallback {
    background-color: $color--teal;
    background-size: cover;
    height: 100%;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;

    // Make a square
    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  &__title {
    @include small-feature-title;

    p {
      all: unset;
    }
  }
}
