.sf-quote-simple {
  margin: 40px 0;

  &__quote {
    @include font-size(epsilon);

    color: $color--navy;
    font-weight: $weight--bold;
    letter-spacing: 0.06em;
    margin: 0 0 15px;

    @include media-query(tablet-landscape) {
      @include font-size(gamma);
    }
  }

  &__cite {
    display: block;
    font-family: $font--primary;
    font-style: normal;
    text-align: right;
  }

  &__author {
    @include font-size(epsilon);

    font-weight: $weight--bold;
    margin: 0;

    @include media-query(tablet-landscape) {
      display: inline-block;
      margin-right: 5px;
    }
  }

  &__title {
    @include font-size(zeta);

    color: $color--dark-grey-70;
    font-weight: 500;
    margin: 0;

    @include media-query(tablet-landscape) {
      display: inline-block;
    }
  }
}
