@mixin caption {
    background-color: var(--black, #222);
    color: var(--white, #FFF);
    font-family: $font--primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

@mixin image-mask {
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    mask-size: contain;
    -webkit-mask-size: contain;
}

// Variables
$slanted-width: 645px;
$slanted-height: 500px;
$slanted-width-sm: 350px;
$slanted-height-sm: 400px;

$wavy-height: 546px;
$wavy-height-md: 517px;
$wavy-width: 465px;
$wavy-width-md: 382px;

.image-with-caption {
    width: 100%;

    &__image {
        width: 100%;
        max-height: 500px;
        object-fit: contain;
    }

    &__caption {
        @include caption;

        display: inline-block;
        padding: var(--size-5, 5px) 15px;
        max-width: 100%;

        &--black {
            color: var(--black, #222);
        }

        .rich-text {
            margin: 0;
            max-width: unset;

            p {
                margin: 0;
            }
        }
    }
}

.image-with-frame {
    position: relative;
    transition: background-color $transition;

    * {
        transition: background-color $transition;
    }

    &--wavy {
        width: $wavy-width;
        height: $wavy-height;

        @media (max-width: $sw-desktop) {
            height: $wavy-height-md;
            width: $wavy-width-md;
        }
    }

    &--slanted {
        height: $slanted-height;
        width: $slanted-width;

        @media (max-width: $sw-mobile) {
            height: $slanted-height-sm;
            width: $slanted-width-sm;
        }
    }

    &__link {
        &:hover {
            cursor: pointer;

            .image-with-frame__frame {
                background-color: var(--purple, $color--purple) !important;
            }

            .image-with-frame__caption {
                background-color: var(--purple, $color--purple);
            }
        }
    }

    &__frame {
        @include image-mask;

        position: absolute;
        background-color: #222;
        z-index: 1;
        height: 100%;
        width: 100%;

        &--wavy {
            -webkit-mask-image: url("~images/frames/frame-wavy.svg");
            mask-image: url("~images/frames/frame-wavy.svg");
        }

        &--slanted {
            -webkit-mask-image: url("~images/frames/frame-slanted.svg");
            mask-image: url("~images/frames/frame-slanted.svg");
        }
    }

    &__image {
        @include image-mask;

        width: 100%;
        height: 100%;
        flex-shrink: 0;
        background-size: cover;
        background-repeat: no-repeat;

        &--wavy {
            -webkit-mask-image: url("~images/frames/wavy-frame-1--clipping_with_box-try_2.svg");
            mask-image: url("~images/frames/wavy-frame-1--clipping_with_box-try_2.svg");
        }

        &--slanted {
            -webkit-mask-image: url("~images/frames/frame-slanted-fill.svg");
            mask-image: url("~images/frames/frame-slanted-fill.svg");
            border: 4px solid var(--black, #222);
        }
    }

    &__caption {
        @include caption;

        position: relative;
        padding: 15px;
        z-index: 2;
        transition: background-color $transition;

        &--wavy {
            left: 38px;
            top: -3px;
            width: 369px;

            @media (max-width: $sw-desktop) {
                top: -36px;
                left: 34px;
                width: 301px;
            }
        }

        &--slanted {
            top: -28px;
            left: 28px;
            width: 366px;
            // top: -36px;
            // left: 36px;
            // width: 465px;

            @media (max-width: $sw-mobile) {
                top: -78px;
                left: 15px;
                width: 200px;
            }
        }

        .rich-text {
            margin: 0;
            padding: 0;

            p {
                margin: 0;
            }
        }
    }
}
