.gallery-item {
    $root: &;

    color: $color--dark-grey;
    display: block;
    margin: 0 3% 10px 0;
    overflow: hidden;
    position: relative;
    width: 48.5%;

    &:nth-child(2n+2) {
        margin-right: 0;
    }

    .gallery-grid & {
        display: none;
    }

    // only show the first six on the grid
    &:nth-child(-n+6) {
        .gallery-grid & {
            display: block;
        }
    }

    @include media-query(tablet-landscape) {
        margin: 0 1.5% 20px 0;
        width: 32.3%;

        &:nth-child(2n+2) {
            margin-right: 1.5%;
        }

        &:nth-child(3n+3) {
            margin-right: 0;
        }
    }

    &:hover {
        color: currentColor;
        cursor: url('~images/expand-cursor.png'), auto;

        #{$root}__image {
            > img {
                filter: saturate(0);
            }

            &::after {
                opacity: 1;
            }
        }
    }

    &__image {
        position: relative;

        // Overlay
        &::after {
            @include teal-overlay;
        }

        > img {
            width: 100%;
        }

        > video {
            height: auto;
            width: 100%;
            object-fit: cover;
        }
    }

    &__caption {
        background-color: $color--sand;
        padding: 10px 20px 10px 0;
        transition: transform 0.4s ease;
        z-index: 3;

        @include media-query(tablet-landscape) {
            left: 0;
            max-width: 65%;
            padding: 10px 20px 0 0;
            position: absolute;
            transform: translateY(0);

            #{$root}:hover & {
                transform: translateY(-100%);
            }
        }
    }

    &__title {
        font-style: italic;
        font-weight: $weight--normal;
        line-height: 1.4;
        margin: 0;

        .gallery-open & {
            color: $color--navy;
            font-size: 18px;
            font-weight: $weight--bold;
            margin: 0;

            @include media-query(tablet-portrait) {
                font-size: 20px;
            }

            @include media-query(tablet-landscape) {
                flex-basis: 45%;
                margin-left: 80px;
                padding-right: 20px;
            }
        }
    }

    &__name {
        font-size: 14px;
        font-weight: $weight--light;
        line-height: 1.4;
        margin: 0;
    }

    &__year,
    &__program,
    &__materials {
        display: none;

        @include media-query(tablet-landscape) {
            // show when gallery is open
            .gallery-open & {
                display: block;
            }
        }

        // hide on thumbnails
        .gallery-grid & {
            display: none;
        }
    }

    &__meta {
        .gallery-open & {

            @include media-query(tablet-landscape) {
                flex-basis: 25%;
                height: 100%;
            }

            > p {
                font-size: 16px;
                line-height: 1.3;
                margin: 0;
                padding: 2px 30px 2px 0;
            }
        }
    }

    &__inner {
        color: $color--black;
        font-family: $font--primary;

        .gallery-open & {
            display: flex;
            flex-direction: column;

            @include media-query(tablet-landscape) {
                align-items: center;
                flex-direction: row;
                height: 100%;
                justify-content: flex-start;
            }
        }
    }

    &__full-work {
        display: none;
        margin-top: 5px;

        @include media-query(tablet-landscape) {
            margin: 0;
        }

        .gallery-open & {
            display: block;
        }

        > a {
            @include link-border;
        }
    }
}
