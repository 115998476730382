.media-panel {
  $root: &;

  background-color: $color--sand;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 540px;
  padding: 70px 0 135px;
  position: relative;

  @include media-query(tablet-portrait) {
    min-height: 720px;
    padding: 227px 0 106px;
  }

  @include media-query(tablet-landscape) {
    height: 576px;
    padding: 0;
  }

  @include media-query(desktop) {
    height: 620px;
  }

  @include media-query(desktop-wide) {
    height: 1066px;
  }

  // Faint overlay
  &::after {
    background:
      linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.3) 0%,
        transparent 75%,
        transparent 100%
      );
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;

    @include media-query(tablet-portrait) {
      background:
        linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.3) 0%,
          transparent 50%,
          transparent 100%
        );
    }
  }

  // notch
  &:not(:first-of-type) {
    -webkit-clip-path:
      polygon(
        75% 20px,
        75% 0%,
        0% 0%,
        0% 100%,
        100% 100%,
        100% 20px
      ); // sass-lint:disable-line no-vendor-prefixes

    clip-path:
      polygon(
        75% 20px,
        75% 0%,
        0% 0%,
        0% 100%,
        100% 100%,
        100% 20px
      );
    margin-top: -22px;

    @include media-query(tablet-portrait) {
      -webkit-clip-path:
        polygon(
          85% 20px,
          85% 0%,
          0% 0%,
          0% 100%,
          100% 100%,
          100% 20px
        ); // sass-lint:disable-line no-vendor-prefixes

      clip-path:
        polygon(
          85% 20px,
          85% 0%,
          0% 0%,
          0% 100%,
          100% 100%,
          100% 20px
        );
    }
  }

  &__image {
    @include media-query(tablet-portrait) {
      padding: 227px 0 126px;
    }
  }

  &__content {
    bottom: 70px;
    margin-left: 20px;
    max-width: 500px;
    position: absolute;
    width: 75%;
    z-index: 2;

    @include media-query(tablet-portrait) {
      margin-left: 30px;
      width: 50%;
    }

    @include media-query(tablet-landscape) {
      bottom: 125px;
      margin-left: 50px;
      width: 50%;
    }

    @include media-query(desktop) {
      margin-left: 80px;
      width: 35%;
    }
  }

  &__heading {
    color: $color--white;
    font-size: 50px;
    line-height: 55px;
    margin: 0 0 20px;
    position: relative;
    z-index: 2;

    @include media-query(tablet-portrait) {
      font-size: 65px;
      line-height: 70px;
    }

    @include media-query(tablet-landscape) {
      font-size: 80px;
      line-height: 85px;
    }
  }

  &__description {
    &-container {
      margin: 0 0 0 10px;
      @include media-query(tablet-landscape) {
        width: 75%;
      }
    }

    position: relative;
    z-index: 2;
    background-color: $color--teal;
    border: 0;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    box-shadow: 10px 0 0 $color--teal, -10px 0 0 $color--teal;
    display: inline;
    margin: 0;
    padding: 5px 0;

    &:hover {
      background-color: $color--purple;
      box-shadow: 10px 0 0 $color--purple, -10px 0 0 $color--purple;

      a {
        color: $color--white;
      }

      span {
        color: $color--white;
      }
    }

    a {
      font-family: $font--primary;
      font-size: 18px;
      font-weight: $weight--bold;
      line-height: 38px;
      color: #000;
      transition: color $transition;
    }

    span {
      color: $color--black;
      padding-left: 5px;
      transition: color $transition;
    }
  }

  &__video {
    display: none;

    // make poster fill container
    height: 100%;
    object-fit: cover;
    width: 100%;

    @include media-query(tablet-landscape) {
      display: block;
    }
  }

  &__caption {
    display: none;
    right: 10%;
    bottom: 35px;
    left: 20px;
    position: absolute;
    z-index: 1;

    @include media-query(tablet-landscape) {
      display: inline-block;
      left: 50px;
    }

    @include media-query(desktop) {
      left: 80px;
    }
  }

  &__arrow {
    animation: ease-up-down 8s infinite;
    bottom: 60px;
    display: block;
    height: 55px;
    position: absolute;
    right: 5%;
    z-index: 2;

    &:hover {
      svg {
        transform: rotate(90deg) translateX(20px);
      }
    }

    svg {
      fill: $color--white;
      height: 30px;
      transform: rotate(90deg);
      transition: transform $transition;
      vertical-align: -25px;
      width: auto;
    }
  }

  &__next {
    @include hidden;
  }
}
