// Homepage events and key dates

$slide-desktop: 540px;
$slide-tablet: 680px;
$slide-mobile: 400px;
$slide-padding: 40px;
$date-padding: 15px;
$deco-height: 8px;
$deco-width: 131px;

.ekd {
    display: grid;
    grid-template-columns: $slide-desktop auto;
    gap: var(--size-80, 80px);
    padding: var(--size-layout-section-spacing, 40px) var(--size-layout-gutter, 140px);

    @media (max-width: $sw-desktop) {
        grid-template-columns: 50% auto;
    }

    @media (max-width: $sw-tablet-landscape) {
        display: block;
        grid-template-columns: unset;
    }

    &__column {
        @media (max-width: $sw-tablet-landscape) {
            margin-bottom: var(--size-40, 40px);
        }
    }

    &__events-container {
        padding-top: $deco-height;
    }

    &__event {
        position: relative;
        margin-bottom: 20px;
    }

    &__slide {
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 500px;

        @media (max-width: $sw-desktop) {
            height: 400px
        }

        @media (max-width: $sw-tablet-landscape) {
            max-height: 60vh;
        }

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        &:hover {
            .ekd__cta {
                background-color: var(--purple, $color--purple);
            }
        }
    }

    &__slide-content {
        height: inherit;
        width: inherit;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px;
    }

    &__title {
        .rich-text {
            display: flex;
            flex-direction: column;

            p {
                display: inline-block;
                background-color: var(--white, $color--white);
                color: var(--black, #222);
                font-family: $font--primary;
                font-size: 50px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%; // 65px
                padding: 0 10px;
                width: fit-content;

                @media (max-width: $sw-tablet-portrait) {
                    font-size: 40px;
                }

                &:not(:last-child) {
                    margin-bottom: 5px !important;
                }
            }
        }
    }

    &__cta {
        display: flex;
        min-width: 100px;
        padding: var(--size-5, 5px) var(--size-15, 15px);
        justify-content: center;
        align-items: center;
        color: var(--white, $color--white);
        background-color: var(--black, $color--black);
        width: fit-content;
        position: absolute;
        bottom: 20px;

        p {
            font-family: $font--primary;
        }
    }

    &__decoration {
        height: $deco-height;
        width: $deco-width;
        background-color: var(--black, $color--black);
        position: absolute;

        &--n {
            top: 1px;
            right: 0px;
        }

        &--ne {
            top: 70px;
            right: -69px;
        }

        &--s {
            bottom: 56px;
            left: 0px;

            @media (max-width: $sw-desktop) {
                bottom: 53px;
                left: 0px;
            }
        }

        &--sw {
            left: -69px;
            bottom: 124px;
            
            @media (max-width: $sw-desktop) {
                left: -69px;
                bottom: 122px;
            }
        }

        &--sw,
        &--ne {
            transform: rotate(90deg)
        }
    }

    &__date {
        width: 100%;
        font-family: $font--primary;
    }

    &__arrows {
        position: absolute;
        bottom: 10px;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__arrow-btn {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background-color: unset;
        border: unset;

        &:hover {
            cursor: pointer;
            background-color: $color--purple;

            .ekd__arrow {
                background-color: $color--white;
            }
        }
    }

    &__arrow {
        @include icon;

        background-color: $color--black;
        width: 12px;
        height: 24px;

        &--next {
            @include arrow-right-icon;
        }

        &--prev {
            @include arrow-left-icon;
        }
    }

    .rich-text {
        margin: unset;
        max-width: 100%;
        padding: 0;

        p {
            margin: 0;
        }
    }
}