:root {
    /* COLOR */

    /* global */
    --color-teal: #00BFB3;
    --color-yellow: #FFC845;
    --color-lime: #B7BF10;
    --color-pink: #F1BDC8;
    --color-orange: #E57200;
    --color-purple: #563D82;
    --color-white: #FFF;
    --color-black: #222;
    --color-navy: #005776;

    /* alias */
    --color-surface-primary: var(--color-white);
    --color-surface-primary-text: var(--color-black);

    /* SIZE */

    /* global */
    --size-5: 5px;
    --size-10: 10px;
    --size-15: 15px;
    --size-20: 20px;
    --size-25: 25px;
    --size-30: 30px;
    --size-40: 40px;
    --size-80: 80px;
    --size-110: 110px;
    --size-130: 130px;
    --size-140: 140px;
    --size-200: 200px;
    --size-280: 280px;
    --size-640: 640px;

    /* alias */
    --size-layout-gutter: var(--size-20);
    --size-layout-item-gap: var(--size-15);
    --size-layout-element-spacing: var(--size-25);
    --size-layout-section-spacing: var(--size-40);
    --size-layout-heading-spacing: var(--size-30);
    --size-layout-large-spacing: var(--size-110);
    --size-layout-xlarge-spacing: var(--size-130);
    --size-layout-restricted-column: var(--size-640);

    /* component */
    --size-notch: var(--size-20);
    --size-notch-large: var(--size-30);

    /* FONT FAMILY */

    /* global */
    --font-brown: 'lineto', arial, sans-serif;
    --font-merriweather: 'merriweather', arial, sans-serif;

    /* alias */
    --font-family-title: var(--font-brown);
    --font-family-heading: var(--font-brown);
    --font-family-intro: var(--font-brown);
    --font-family-quote: var(--font-brown);
    --font-family-subheading: var(--font-brown);
    --font-family-label: var(--font-brown);
    --font-family-body: var(--font-merriweather);
    --font-family-detail: var(--font-brown);

    /* FONT SIZE */

    /* global */
    --font-size-10: 10px;
    --font-size-12: 12px;
    --font-size-14: 14px;
    --font-size-16: 16px;
    --font-size-18: 18px;
    --font-size-20: 20px;
    --font-size-24: 24px;
    --font-size-32: 32px;
    --font-size-40: 40px;
    --font-size-50: 50px;
    --font-size-70: 70px;
    --font-size-80: 80px;

    /* alias */
    --font-size-title: var(--font-size-50);
    --font-size-heading: var(--font-size-40);
    --font-size-intro: var(--font-size-24);
    --font-size-quote: var(--font-size-24);
    --font-size-subheading: var(--font-size-20);
    --font-size-label: var(--font-size-18);
    --font-size-body: var(--font-size-16);
    --font-size-detail: var(--font-size-10);

    /* FONT WEIGHT */

    /* global */
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-heavy: 700;

    /* alias */
    --font-weight-title: var(--font-weight-heavy);
    --font-weight-heading: var(--font-weight-normal);
    --font-weight-intro: var(--font-weight-light);
    --font-weight-quote: var(--font-weight-normal);
    --font-weight-subheading: var(--font-weight-heavy);
    --font-weight-label: var(--font-weight-normal);
    --font-weight-body: var(--font-weight-light);
    --font-weight-detail: var(--font-weight-light);

    /* LINE HEIGHT */

    /* global */
    --line-height-110: 110%;
    --line-height-120: 120%;
    --line-height-130: 130%;
    --line-height-140: 140%;
    --line-height-150: 150%;
    --line-height-160: 160%;
    --line-height-170: 170%;
    --line-height-180: 180%;

    /* alias */
    --line-height-title: var(--line-height-110);
    --line-height-heading: var(--line-height-120);
    --line-height-intro: var(--line-height-150);
    --line-height-quote: var(--line-height-180);
    --line-height-subheading: var(--line-height-130);
    --line-height-label: var(--line-height-140);
    --line-height-body: var(--line-height-180);
    --line-height-detail: var(--line-height-160);
}

@include media-query(tablet-portrait) {
    :root {
        --size-layout-gutter: var(--size-80);
        --size-layout-heading-spacing: var(--size-layout-large-spacing);
        --font-size-title: var(--font-size-70);
        --font-size-heading: var(--font-size-50);
        --font-size-intro: var(--font-size-32);
        --font-size-quote: var(--font-size-32);
        --font-size-subheading: var(--font-size-24);
        --font-size-label: var(--font-size-20);
        --font-size-detail: var(--font-size-12);
        --line-height-title: var(--line-height-120);
        --line-height-quote: var(--line-height-170);
    }
}

@include media-query(tablet-landscape) {
    :root {
        --size-layout-gutter: var(--size-130);
        --font-size-title: var(--font-size-80);
    }
}

@include media-query(desktop) {
    :root {
        --size-layout-gutter: var(--size-140);
    }
}

@include media-query(desktop-wide) {
    :root {
        --size-layout-gutter: var(--size-200);
    }
}
