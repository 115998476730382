.apply-overlay {
  height: calc(100vh - 70px);
  overflow: scroll;
  width: 100%;
  position: fixed;
  top: 70px;
  z-index: 20;
  background-color: rgba(255, 255, 255, 0.95);
  transform: translateY(calc(-100% - 70px));
  transition: transform 0.25s ease-out, opacity 0.25s ease-out;

  // Mobile Styling
  padding: 40px 20px;

  @include media-query(tablet-portrait) {
    padding: 40px 80px;
  }


  @include media-query(tablet-landscape) {
    padding: 140px;
  }

  @include media-query(desktop-mid) {
    padding: 140px 200px;
  }

  &--active {
    transform: translateY(0);
  }

  &__column {
    &--left {
      @include media-query(tablet-landscape) {
        max-width: 650px;
        margin-right: 80px;
      }
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &__title {
    font-size: 2.25rem;
    margin-bottom: 40px;
  }

  &__button {
    font-family: $font--primary;
    padding: 20px 40px;
    width: 100%;
    margin-bottom: 25px;
    font-size: 1.25rem;
    border: none;
    transition: $transition;

    @include media-query(tablet-landscape) {
      width: 300px;
    }

    @include media-query(desktop-mid) {
      width: 350px;
    }

    &:hover {
      background-color: $color--purple !important;
      color: $color--white !important;
      cursor: pointer;
    }
  }

  &__buttons {
    &--mobile {
      display: block;
    }

    &--desktop {
      display: none;
    }

    @include media-query(tablet-landscape) {
      &--mobile {
        display: none;
      }

      &--desktop {
        display: block;
      }
    }
  }

  &__body {
    .rich-text {
      margin-left: 0;
    }
  }

  .sf-section__wrapper {
    padding: 0;
  }

  .rich-text {
    padding: 0;
  }
}
