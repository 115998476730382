.sf-multicolumn-list {
    &__heading {
        font-weight: $weight--normal;
        line-height: 1.3;
        margin: 0 0 5px;

        @include media-query(tablet-landscape) {
            font-size: 24px;
        }
    }

    .rich-text {
        // sass-lint:disable placeholder-in-extend
        @extend .rich-text--full;
    }

    ul {
        margin: 20px 0 25px 30px;

        @include media-query(tablet-portrait) {
            column-count: 2;
        }

        @include media-query(tablet-landscape) {
            column-count: 3;
        }

        li {
            display: inline-block;
            margin: 0 0 15px;
            padding: 0 0 0 20px;
            position: relative;
            width: 100%;

            @include media-query(tablet-portrait) {
                padding-right: 20px;
            }

            &::before {
                background-color: $color--teal;
                border-radius: 50%;
                content: '';
                display: inline-block;
                height: 8px;
                left: 0;
                margin-right: 10px;
                position: absolute;
                top: 8px;
                width: 8px;
            }
        }

        a {
            font-size: 16px;

            @include media-query(desktop) {
                font-size: 17px;
            }
        }
    }
}
