.home {
    &__body {
        width: 100%;
        overflow: hidden;
    }

    &__hero {
        width: 100%;
        min-height: calc(100vh - 62px); // 100vh minus the height of header
        overflow: hidden;
        background-size: cover;
        position: relative;
    }

    &__hero-content {
        position: relative;
        z-index: 2;
        padding: var(--size-40, 40px);
        min-height: inherit;

        @media (max-width: $sw-tablet-portrait) {
            padding: var(--size-40, 40px) 20px;
        }
    }

    &__animated-text {
        position: absolute;
        bottom: 100px;

        @media (max-width: $sw-tablet-portrait) {
            bottom: 60px;
        }
    }

    &__video-hero {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100vh - 62px);
    }

    &__wordmark {
        margin-bottom: 10px;
        width: 50%;
    }

    &__heading {
        width: fit-content;
        margin-bottom: 70px;

        .rich-text {
            all: unset;

            h1 {
                display: table;
                line-height: 130%;
                background-color: $color--white;
                margin-bottom: 10px;
                font-size: 50px;
                padding: 5px 20px;

                @media (max-width: $sw-tablet-landscape) {
                    font-size: 40px;
                }

                @media (max-width: $sw-tablet-portrait) {
                    margin-bottom: 5px;
                    font-size: 24px;
                    padding: 5px 10px;
                }
            }
        }
    }

    &__arrow {
        position: absolute;
        right: var(--size-40, 40px);
        bottom: var(--size-40, 40px);
    }

    &__arrow-button {
        all: unset;

        &:hover {
            cursor: pointer;
        }
    }

    &__arrow-link {
        &:focus, &:focus-within {
            outline-color: $color--purple;
            outline-offset: 5px;
        }
    }

    &__arrow-svg {
        background-color: $color--black;
        height: 110px;
        width: 80px;
        display: inline-block;
        mask-size: contain;
        -webkit-mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-position: center;

        &:hover {
            background-color: $color--purple;
        }

        @media (max-width: $sw-tablet-portrait) {
            height: 80px;
        }
    }

    &__gradient {
        position: absolute;
        z-index: 1;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80.47%, #FFF 100%);
    }
}
