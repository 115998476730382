.splide {
  $root: &;

  &__pagination {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    pointer-events: none;

    li {
      display: inline-block;
      line-height: 1;
      list-style-type: none;
      margin: 0;
      pointer-events: auto;
    }
  }

  &:not( .is-overflow ) {
    #{ $root }__pagination {
      display: none;
    }
  }
}
