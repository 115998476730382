.section-journey-heading {
    position: relative;
    padding: var(--size-layout-large-spacing) var(--size-layout-gutter) calc(var(--size-layout-large-spacing) + var(--size-notch));
    // 110px 20-200px 130px
    margin-bottom: var(--size-layout-section-spacing); //40px

    &__title {
        margin: 0;
        display: inline-block;
        color: var(--color-black);
        font-family: var(--font-family-heading);
        font-size: var(--font-size-heading);
        font-weight: var(--font-weight-heading);

        // Text background
        line-height: var(--line-height-140);
        padding: 10px 20px;
        text-wrap: balance;

        span {
            -webkit-box-decoration-break: clone;
            background-color: var(--color-white);
            box-shadow: -20px 0 0 5px var(--color-white), 20px 0 0 5px var(--color-white);
            position: relative;
        }
    }

    &::after {
        background-color: var(--color-white);
        content: '';
        height: var(--size-notch);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 65%;
    }

    &__caption {
        color: var(--color-white);
        padding: var(--size-5) var(--size-10);
        margin: 0;
        width: 65%;
        font-size: var(--font-size-detail);
        font-family: var(--font-family-detail);
        font-weight: var(--font-weight-detail);
        line-height: var(--line-height-detail);
        position: absolute;
        bottom: var(--size-notch); // 20px
        left: 0;
        text-align: left;

        .rich-text {
            margin: 0;
            padding: 0;
        }

        p {
            margin: 0;
            line-height: inherit;
        }

        a {
            color: inherit;
            font-weight: inherit;
            font-size: inherit;
        }
    }
}
