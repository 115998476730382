.logo-block {
  $root: &;

  text-align: center;

  &.is-open {
    img {
      display: block;
    }

    #{$root}__less {
      display: flex;
    }

    #{$root}__more {
      display: none;
    }
  }

  &__tiles {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    @include media-query(tablet-portrait) {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  img {
    display: block;
    margin: 0 0 10px;
    padding: 10px;

    @supports (object-fit: contain) {
      object-fit: contain;
    }
  }

  &__icon {
    fill: $color--teal;
    height: 22px;
    margin-right: 5px;
    pointer-events: none;
    width: 12px;

    &--open {
      transform: rotate(45deg);
    }

    #{$root}__toggle:focus &,
    #{$root}__toggle:active & {
      fill: $color--teal;
    }

    #{$root}__toggle:hover & {
      fill: $color--purple;
    }
  }

  &__more, &__less {
    color: $color--teal;
    display: inline-block;
    font-family: $font--primary;
    font-size: 14px;
    margin: 10px auto;

    @include media-query(desktop) {
      margin-top: 0;
    }

    &.is-hidden {
      display: none;
    }

    &:active,
    &:focus {
      color: $color--teal;
    }

    &:hover {
      color: $color--purple;
    }
  }

  &__less {
    display: none;

    span {
      padding-right: 5px;
    }
  }
}

.logo-block-item {
  &--hidden {
    display: none;
  }

  &__media {
    max-width: 300px;
    height: auto;
    object-fit: contain;
    width: 100%;
  }
}
