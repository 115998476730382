.apply {
    position: relative;
    z-index: 4;
    background-color: $color--white;
    margin-right: 20px;
    margin-top: 40px + 60px;
    max-width: 85%;
    padding-right: 10px;
    padding-bottom: 40px;

    @include media-query(tablet-portrait) {
        margin-top: 64px + 60px;
        margin-right: 80px;
        max-width: 75%;
        padding-right: 80px;
    }

    @include media-query(tablet-landscape) {
        margin-top: 80px + 60px;
        margin-right: 80px;
        max-width: 786px;
        padding-right: 140px;
    }


    @include media-query(desktop) {
        margin-top: 110px + 60px;
        max-width: 1024px;
    }

    &__title {
        padding: 20px;
        padding: 0 20px;

        @include media-query(tablet-portrait) {
            padding: 40px 80px;
        }

        @include media-query(tablet-landscape) {
            padding: 40px 130px;
        }

        @include media-query(desktop) {
            padding: 40px 140px;
        }

        @include media-query(desktop-wide) {
            padding: 40px 140px 40px 200px;
        }
    }

    &__introduction {
        font-size: 24px;
        font-family: $font--primary;
    }

    &__body {
        margin-top: 80px;
    }
}
