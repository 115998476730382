.divisions {
    margin: 30px 0 20px;

    @include media-query(tablet-portrait) {
        display: flex;
        flex-wrap: wrap;
        margin: 35px 0 20px;
    }
}

.division-teaser {
    $root: &;


    display: block;
    margin-bottom: 10px;

    @include media-query(tablet-portrait) {
        margin: 0 5% 40px 0;
        width: 47.5%;

        &:nth-child(even) {
            margin-right: 0;
        }
    }

    &__title {
        background-color: $color--dark-grey;
        color: $color--teal;
        display: inline-block;
        font-size: 22px;
        margin-bottom: 15px;
        padding: 5px 40px 5px 20px;
        transition: background-color, color, 0.2s ease;

        @include media-query(desktop) {
            font-size: 34px;
        }

        #{$root}:hover & {
            background-color: $color--teal;
            color: $color--dark-grey;
        }
    }

    &__description {
        color: $color--dark-grey-80;
        font-family: $font--primary;
        font-size: 16px;
        font-weight: $weight--light;
        line-height: 1.5;
        margin: 0 0 5px;

        @include media-query(desktop) {
            font-size: 20px;
        }
    }

    // small cta overrides
    .sf-cta-small {
        padding-bottom: 0;
    }

    .sf-cta-small__link,
    .sf-cta-small__icon {
        transition: 0.2s ease;
    }

    .sf-cta-small__link {
        font-size: 18px;
    }
}
