.sf-cta-small {
  $root: &;


  display: inline-block;
  padding-bottom: 25px;
  position: relative;

  .sf-feature-single & {
    margin-top: 20px;
    padding: 0;

    @include media-query(tablet-portrait) {
      margin-top: 40px;
    }
  }

  .curriculum &,
  .sf-section--gallery & {
    padding-bottom: 0;
  }

  &__link {
    color: $color--navy;
    display: inline-block;
    font-family: $font--primary;
    font-size: 20px;
    padding: 0 25px 25px 0;
    transition: $transition;

    &:hover,
    .division-teaser:hover & {
      color: $color--purple;

      #{$root}__icon {
        fill: $color--purple;
        transform: translateX(20px);
      }
    }
  }

  &__icon {
    fill: $color--navy;
    height: 22px;
    position: absolute;
    right: 0;
    top: 30px;
    transition: $transition;
    width: 40px;
  }

  &__wrapper {
    margin-left: 12px;
  }
}
