.sf-feature-2-up {
  $root: &;

  &:hover {
    #{$root}__image {
      > img, video {
        filter: saturate(0);
      }

      &::after {
        opacity: 1;
      }
    }

    #{$root}_title {
      text-decoration-color: $color--purple;
    }
  }

  @include media-query(tablet-landscape) {
    &:hover {
      #{$root}__title {
        > p {
          border-color: $color--dark-grey;
        }
      }
    }
  }

  @include media-query(desktop) {
    display: grid;
    grid-template-columns: 200px auto;
    grid-gap: 30px;
  }

  &__media {
    width: 100%;
    object-fit: cover;
    height: 400px;

    @include media-query(tablet-portrait) {
      height: 300px;
    }

    @include media-query(tablet-landscape) {
      height: 400px;
    }

    @include media-query(desktop) {
      height: 200px;
    }
  }

  &__image {
    display: inline-block;
    width: 100%;
    position: relative;

    @include media-query(tablet-portrait) {
      width: 300px;
      height: 300px;
    }

    @include media-query(tablet-landscape) {
      width: 400px;
      height: 400px;
    }

    @include media-query(desktop) {
      width: 200px;
      height: 200px;
    }

    > img {
      width: 100%;
    }

    // Overlay
    &::after {
      @include teal-overlay;

      @include media-query(desktop) {
        max-height: 275px;
        max-width: 275px;
      }
    }
  }

  &__image-fallback {
    background-color: $color--teal;
    height: 100%;
    max-width: 355px;
    position: relative;
    z-index: 2;

    @include media-query(desktop) {
      min-height: 275px;
      min-width: 275px;
    }

    // Make a square
    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  &__title {
    @include small-feature-title;

    padding-top: 30px;
    @include media-query(desktop) {
      margin: 0 0 5px;
      padding: 30px 0;
    }

    &:hover {
      text-decoration-color: $color--purple;
    }

    p {
      all: unset;
    }
  }
}
