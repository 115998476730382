.top-link-container {
    display: none;
    position: relative;

    @include media-query(tablet-portrait) {
        display: block;
    }
}

.top-link {
    border-bottom: 0;
    color: $color--dark-grey-50;
    font-family: $font--primary;
    font-size: 15px;
    opacity: 0;
    padding: 30px 10px 10px 20px;
    position: absolute;
    top: 0;
    transition: opacity, color, $quick-transition;

    &:focus,
    &:hover {
        color: $color--teal;

        svg {
            bottom: 5px;
            fill: $color--teal;
        }
    }

    &.is-fixed {
        opacity: 1;
        position: fixed;
    }

    span {
        display: none;

        @include media-query(tablet-landscape) {
            display: inline-block;
        }
    }

    svg {
        bottom: 0;
        fill: $color--dark-grey-50;
        height: 15px;
        position: relative;
        transform: rotate(270deg);
        transition: bottom $quick-transition;
        width: 30px;
    }
}
