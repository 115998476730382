// keep the footer at the bottom of the page
.footer-container {
    margin-top: auto;
}

.footer {
    background-color: $color--dark-grey;
    margin-top: 55px;
    padding: 40px 0;

    &--simplified {
        margin-top: 200px;
    }

    .template-home-page & {
        margin-top: -1px;
        position: relative;

        &::before {
            background-color: $color--dark-grey;
            content: '';
            height: 20px;
            left: 0;
            position: absolute;
            top: -20px;
            width: 75%;
            z-index: 1;

            @include media-query(tablet-portrait) {
                width: 60%;
            }
        }
    }

    &__grid {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
        justify-content: flex-start;
        margin: 20px 0;
        text-align: left;

        @include media-query(tablet-portrait) {
            grid-template-columns: repeat(4, 1fr);
        }

        ul {
            &:last-of-type {
                li {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__inner {
        align-items: center;
        display: flex;
        flex-direction: column;

        @include media-query(tablet-portrait) {
            align-items: flex-end;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__nav-container {
        width: 100%;

        @include media-query(tablet-landscape) {
            flex: 1;
            width: auto;
        }
    }

    &__contact-links {
        margin-bottom: 40px;
        width: 100%;

        > a {
            background-color: $color--white;
            color: $color--dark-grey;
            display: inline-block;
            font-weight: 500;
            padding: 5px 7px;
            transition: color $transition;
            border-bottom: none;

            @include media-query(tablet-portrait) {
                padding: 0 7px 3px;
            }

            &:first-of-type {
                padding: 5px 7px 3px;

                @include media-query(tablet-portrait) {
                    margin-bottom: 0;
                    padding: 5px 7px;
                }
            }

            &:hover {
                color: $color--dark-grey;
                background-color: $color--teal;
            }
        }
    }

    &__social-links {
        a {
            border-bottom: 0;

            &:not(:last-of-type) {
                margin-right: 4px;
            }
        }
    }

    &__social-link {
        fill: $color--white;
        height: 30px;
        transition: $quick-transition;
        width: 30px;

        &--facebook {
            &:hover {
                fill: #3B5998;
            }
        }

        &--linkedin {
          &:hover {
            fill: #0077B5;
          }
        }

        &--tiktok {
          &:hover {
            fill: #EE1D52;
          }
        }


        &--twitter {
            &:hover {
                fill: #55ACEE;
            }
        }

        &--youtube {
            &:hover {
                fill: #F00;
            }
        }

        &--instagram {
            &:hover {
                fill: #E95950;
            }
        }
    }

    &__heading {
        display: none;

        @include media-query(tablet-portrait) {
            display: block;
            margin: 0 20px 0 0;
        }
    }

    &__credit {
        > p {
            color: rgba(255, 255, 255, 0.5);
            font-family: $font--primary;
            font-size: 13px;
            line-height: 1.1;
            margin: 24px 0 0;
        }
    }

    &__addresses {
        align-items: baseline;
        display: flex;
        flex-direction: row;
        grid-column: 1 / 3;
        grid-row: 2;

        @include media-query(tablet-portrait) {
            align-items: baseline;
            flex-direction: column;
            grid-column: auto;
            grid-row: auto;
            padding-right: 25px;
        }

        @include media-query(desktop-wide) {
            padding-right: 0;
        }

        .nav__item--footer {
            width: 50%;

            @include media-query(tablet-portrait) {
                width: 100%;
            }

            &:first-of-type {
                padding-right: 10px;

                @include media-query(tablet-portrait) {
                    padding: 0;
                }
            }

            &:last-of-type {

                @include media-query(tablet-portrait) {
                    padding: 0;
                }
            }
        }
    }

    &__address {
        font-style: unset;

        p {
            @include font-size(zeta);

            color: rgba(255, 255, 255, 0.5);
            font-family: $font--primary;
            line-height: 1;
            margin: 0 0 5px;

            &:first-of-type {
                margin-top: 10px;
            }
        }
    }

    &__nav {
        &--mobile {
            .footer__contact-links {
                @include media-query(tablet-portrait) {
                    display: none;
                }
            }
        }

        &--desktop {
            .footer__contact-links {
                display: none;

                @include media-query(tablet-portrait) {
                    display: block;
                }
            }
        }
    }

    &__land-acknowledgement {
        text-align: center;
        font-family: $font--primary;
    }

    &__land-acknowledgement-text {
        font-size: 1rem;
        font-style: italic;
        color: $color--white;

        a {
            color: $color--white;
            border-bottom: 1px solid $color--teal;

            &:hover {
                color: $color--teal;
            }
        }
    }

    hr {
        max-width: 75%;
    }
}
