.related-topics {
    &__list {
        display: block;
        margin: 30px 0 0;

        @include media-query(tablet-portrait) {
            display: grid;
            grid-gap: 5%;
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
