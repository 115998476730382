.sf-feature-single {
  $root: &;

  position: relative;

  &::after {
    background-color: $color--sand;
    bottom: -10px;
    content: '';
    height: 100%;
    left: 15%;
    position: absolute;
    transition: background-color $transition;
    width: 70%;
  }

  &:hover {
    // Show image overlay
    #{$root}__image {
      &::after {
        opacity: 1;
      }

      img {
        filter: saturate(0);
      }
    }

    #{$root}__text {
      > .sf-feature-single__title,
      > .sf-feature-single__description {
        color: $color--dark-grey;
      }

      > .sf-feature-single__title {
        border-bottom: 1px solid $color--dark-grey;
      }
    }
  }

  @include media-query(tablet-portrait) {
    display: flex;
    margin-bottom: 30px;

    &:hover {

      // Change text colour
      #{$root}__text {
        > h2 {
          border-bottom: 0;
        }
      }

      .sf-cta-small {
        &__link {
          color: $color--purple;
        }

        &__icon {
          fill: $color--teal;
          transform: translateX(20px);
        }
      }
    }
  }

  &__media-container {
    background-color: $color--teal;
    background-size: cover;
    position: relative;
    z-index: 2;
    min-height: 350px;
    max-width: 100%;

    @include media-query(tablet-portrait) {
      width: 300px;
      height: 300px;
    }

    @include media-query(tablet-landscape) {
      width: 400px;
      height: 400px;
    }
  }

  &__media {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
    position: absolute;
  }

  &__image {
    background-color: $color--teal;
    background-size: cover;
    height: 100%;
    position: relative;
    z-index: 2;

    @include media-query(tablet-portrait) {
      max-width: 400px;
      width: 35%;
    }

    // Overlay
    &::after {
      @include teal-overlay;
    }

    img {
      display: block;
      transition: filter $transition;
      width: 100%;
    }
  }

  &__text {
    background-color: $color--sand;
    padding: 20px 20px 0;
    position: relative;
    z-index: 2;

    &::before {
      background-color: inherit;
      bottom: -10px;
      content: '';
      height: 10px;
      left: 0;
      position: absolute;
      width: 75%;
    }

    @include media-query(tablet-portrait) {
      margin-top: 10px;
      min-height: 410px;
      padding: 70px 80px 10px 50px;
      transition: background-color $transition;
      width: 65%;

      &::before {
        display: none;
      }
    }

    @include media-query(tablet-landscape) {
      padding: 70px 80px 20px 50px;
    }

    .sf-cta-small {
      &__icon {
        fill: $color--teal;
      }
    }
  }

  &__title {
    display: inline;
    font-size: 24px;
    font-weight: $weight--bold;
    line-height: 1.3;

    @include media-query(tablet-landscape) {
      font-size: 32px;
    }
  }

  &__description {
    font-family: $font--primary;
    font-size: 16px;
    font-weight: $weight--light;
    line-height: 1.5;
    margin: 10px 0 0;

    @include media-query(tablet-landscape) {
      font-size: 20px;
    }
  }

  .rich-text p {
    all: unset;
  }
}
