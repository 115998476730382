.apply-background {
    &__image,
    &__video {
        position: fixed;
        min-height: 100vh;
        width: 100%;
        object-fit: cover;
        object-position: 100% 0;
        bottom: 0;
        right: 0;
    }

    &__video {
        display: none;
        z-index: 2;

        @include media-query(tablet-landscape) {
            display: block;
        }
    }

    &__caption {
        position: fixed;
        bottom: 0;
        right: 8px;
        font-family: $font--primary;
        font-size: 14px;
        color: $color--white;
        z-index: 3;

        .rich-text {
            max-width: unset;
        }

        p {
            text-shadow: 1px 1px 5px #000;
            -moz-text-shadow: 1px 1px 5px #000;
            -webkit-text-shadow: 1px 1px 5px 0 #000;
        }
    }
}
