.journey-cta-block {
    position: relative;
    display: inline-block;
    max-width: var(--size-layout-restricted-column); // 680px
    margin: 0 var(--size-layout-gutter) calc(var(--size-layout-section-spacing) + var(--size-layout-element-spacing)) 0;
    // 0 20-200px 65px 0
    padding: var(--size-layout-element-spacing) 0 var(--size-layout-element-spacing) var(--size-layout-gutter);
    // 25px 0 25px 20-200px

    &::before {
        content: "";
        background-color: var(--color-white);
        position: absolute;
        right: 0;
        top: 0;
        height: var(--size-notch-large); // 30px
        width: var(--size-notch-large); // 30px
    }

    &::after {
        content: "";
        background-color: var(--color-white);
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        width: var(--size-notch); // 20px
    }

    &__button {
        display: inline-block;
        position: relative;
        z-index: 2;
        padding: var(--size-10) var(--size-30);
        max-width: var(--size-280);
        background-color: var(--color-yellow);
        color: var(--color-black);
        transition: color 0.2s, background 0.2s;
        font-family: var(--font-family-label);
        font-size: var(--font-size-label);
        font-weight: var(--font-weight-label);
        line-height: var(--line-height-label);

        &:hover, &:active, &:focus {
            background-color: var(--color-purple);
            color: var(--color-white);

            svg {
                opacity: 1;
                right: calc(-1 * var(--size-layout-item-gap)); // 15px
                fill: var(--color-purple);
            }
        }

        &:active {
            svg {
                right: 0;
            }
        }
    }

    &__arrow {
        pointer-events: none;
        opacity: 0;
        fill: var(--color-black);
        right: calc(-1 * var(--size-layout-section-spacing)); // 40px
        -ms-transform: translate(100%, -50%) rotate(180deg);
        transform: translate(100%, -50%) rotate(180deg);
        top: 50%;
        bottom: auto;
    }
}
