.rich-text {
  margin-bottom: 40px;
  max-width: 640px;
  padding: 0 20px;

  @include media-query(tablet-portrait) {
    margin-left: 80px;
    padding: 0;
  }

  @include media-query(tablet-landscape) {
    margin-left: 130px;
  }

  @include media-query(desktop) {
    margin-left: 140px;
    max-width: 680px;
  }

  @include media-query(desktop-wide) {
    margin-left: 200px;
    max-width: 800px;
  }

  // strip rich text styles
  &--full,
  .sf-section &,
  .caption & {
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: 100%;

    @include media-query(tablet-portrait) {
      padding: 0;
      width: 100%;
    }

    @include media-query(tablet-landscape) {
      padding: 0;
    }

    @include media-query(desktop) {
      padding: 0;
    }
  }

  p {
    line-height: 1.8;
    margin: 0 0 25px;

    @include media-query(desktop) {
      line-height: 1.9;
    }

    .sf-intro & {
      line-height: inherit;
      margin: inherit;
    }
  }

  a {
    @include font-size(epsilon);

    @include link-border;
    font-family: $font--primary;

    &:focus,
    &:hover {
      color: $color--purple;
    }
  }

  ul,
  ol {
    margin: 0 0 25px 30px;

    li {
      margin-bottom: 15px;

      a {
        font-size: 16px;

        @include media-query(desktop) {
          font-size: 17px;
        }
      }
    }
  }

  ul {
    li {
      padding-left: 20px;
      position: relative;

      &::before {
        background-color: $color--teal;
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 8px;
        left: 0;
        margin-right: 10px;
        position: absolute;
        top: 8px;
        width: 8px;
      }
    }
  }

  ol {
    list-style: inside decimal;
  }

  h3,
  h4,
  h5 {
    font-weight: $weight--normal;
    line-height: 1.3;
    margin-bottom: 5px;
  }

  h3 {
    margin-bottom: 15px;

    @include media-query(tablet-landscape) {
      font-size: 24px;
    }
  }

  h4 {
    color: $color--navy;
    font-weight: $weight--bold;
    margin-top: 30px;
  }
}
