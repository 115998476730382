.table-container {
    overflow: auto;
    position: relative;

    &__hint {
        align-items: center;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) -50%, $color--white 100%);
        content: '';
        display: flex;
        height: 100%;
        justify-content: flex-end;
        opacity: 1;
        padding-right: 20px;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.25s ease;
        width: 150px;

        @include media-query(desktop) {
            display: none;
        }

        svg {
            fill: $color--teal;
            height: 25px;
            width: 40px;
        }

        .is-scrolling & {
            opacity: 0;
        }
    }


    table {
        border-collapse: collapse;
        display: table;
        font-family: $font--primary;
        margin: 0 0 20px;
        overflow-x: scroll;
        table-layout: fixed;
        width: 700px; // allow for mobile scrolling

        @include media-query(tablet-portrait) {
            width: 1000px;
        }

        @include media-query(desktop) {
            width: 100%;
        }

        th {
            text-align: left;
        }

        th,
        td {
            padding: 5px;
        }

        td {
            padding: 5px 10px;
            vertical-align: top;
        }

        thead {
            th {
                border-bottom: 1px solid $color--dark-grey;
            }
        }

        tbody {
            th {
                text-transform: uppercase;
            }

            tr {
                th {
                    padding-left: 0;
                }
            }
        }
    }
}
