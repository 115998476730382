.page-nav-container {
  @include media-query(tablet-portrait) {
    min-height: 56px;
    position: relative;

    .template-division-page &,
    .template-info-page & {
      margin-bottom: 30px;
    }
  }
}

.page-nav {
  $root: &;

  bottom: 0;
  font-family: $font--primary;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 9; // keep it under the fixed cta at z-index: 10

  @include media-query(tablet-portrait) {
    height: 60px;
    position: absolute;
    top: 0;

    &.is-fixed {
      position: fixed;
    }
  }

  &__mobile-trigger {
    @include font-size(milli);

    align-items: center;
    background-color: $color--teal;
    color: $color--black;
    display: flex;
    justify-content: center;
    padding: 12px 0;
    position: relative;
    transition: background-color, color, $quick-transition;

    &:focus,
    &:hover {
      background-color: $color--purple;
      color: $color--white;

      #{$root}__mobile-icon {
        fill: $color--white;
      }
    }

    @include media-query(tablet-portrait) {
      display: none;
    }
  }

  &__mobile-icon {
    fill: $color--black;
    height: 20px;
    margin-right: 10px;
    transition: fill $quick-transition;
    width: 20px;
  }

  &__items {
    background-color: $color--sand;
    border-top: 1px solid $color--black;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 10px 0 15px;
    position: absolute;
    transform: translateY(100%);
    transition: transform, bottom, $transition;
    width: 100%;

    @include media-query(tablet-portrait) {
      background-color: $color--white;
      border: 0;
      bottom: auto;
      display: flex;
      flex-direction: row;
      padding: 0 0 0 60px;
      position: relative;
      transform: translateY(0);
      transition: none;
      width: 100%;

      // preceding line
      &::before {
        background-color: $color--black;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        width: 260px;
      }

      &::after {
        background-color: $color--black;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        width: 260px;
      }
    }

    @include media-query(tablet-landscape) {
      padding: 0 0 0 90px;
    }

    @include media-query(desktop) {
      padding: 0 0 0 120px;
    }

    @include media-query(desktop-wide) {
      padding: 0 0 0 180px;
    }

    &.is-open {
      bottom: 46px;
      transform: translateY(0);
    }
  }

  &__link {
    color: $color--dark-grey-80;
    font-size: 15px;
    padding: 10px 0;

    &:hover {
      cursor: pointer;
    }

    @include media-query(tablet-portrait) {
      border-bottom: 1px solid $color--black;
      padding: 20px 11px 10px;
      position: relative;
      transition: color $transition;

      // lines above and below
      &::after,
      &::before {
        background-color: $color--black;
        content: '';
        height: 0;
        left: 50%;
        position: absolute;
        transition: height $transition;
        width: 1px;
      }

      &::after {
        top: 0;
      }

      &::before {
        bottom: 0;
      }

      @media (hover: hover) {
        &:hover {
          color: $color--purple;

          &::before {
            height: 10px;
          }
        }
      }

      &.is-active {
        color: $color--navy;

        // top line
        &::after {
          height: 20px;
        }

        // bottom line
        &::before {
          height: 10px;
        }
      }
    }

    // Custom breakpoints due to page nav's specific content/width
    @media only screen and (min-width: 1080px) {
      padding: 20px 17px 10px;
    }

    @media only screen and (min-width: 1200px) {
      padding: 20px 25px 10px;
    }

    &--top {
      border-bottom: 0;
      color: $color--dark-grey-50;
      flex-direction: column;
      font-size: 15px;
      height: 100%;

      &:hover {
        color: $color--white;
      }

      &::before {
        display: none;
      }

      @include media-query(tablet-portrait) {
        color: $color--white;
        display: flex;
        flex-direction: initial;
        left: 0;
        opacity: 0;
        padding: 20px 10px 10px 20px;
        position: absolute;
        transition: opacity $transition;

        .is-fixed & {
          opacity: 1;
        }
      }

      @include media-query(tablet-landscape) {
        color: $color--dark-grey-50;

        &:focus,
        &:hover {
          color: $color--purple;

          &::before {
            display: none;
          }

          svg {
            bottom: 5px;
            fill: $color--purple;
          }
        }
      }

      span {
        display: none;

        @include media-query(tablet-landscape) {
          display: block;
        }
      }

      svg {
        bottom: 0;
        fill: $color--dark-grey-50;
        height: 15px;
        position: relative;
        transform: rotate(270deg);
        transition: bottom $quick-transition;
        width: 30px;
      }
    }
  }
}
