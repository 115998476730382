.section {
    &--main {
        max-width: $wrapper--small;

        &:only-child {
            margin: 0 auto;
        }
    }

    &--step {
        padding: 1rem 0;

        @include media-query(tablet-portrait) {
            padding: 70px 0;
        }
    }
}
