.listing-item {
  $root: &;

  display: flex;
  font-family: $font--primary;
  padding: 20px 0 50px;
  position: relative;

  @include media-query(tablet-portrait) {
    margin: 0 0 0 -25px;
  }

  &::after {
    background-color: $color--dark-grey;
    content: "";
    display: none;
    height: 0;
    left: 15px;
    position: absolute;
    top: 10px;
    transition: height 0.2s ease;
    width: 1px;

    @include media-query(tablet-portrait) {
      display: block;
    }
  }

  &::before {
    background-color: $color--dark-grey;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    transition: width 0.2s ease;
    width: 50px;

    @include media-query(tablet-portrait) {
      left: 25px;
    }
  }

  &:hover {
    &::after {
      height: 50px;
    }

    &::before {
      width: 75px;
    }

    #{$root}__image {
      > img {
        filter: saturate(0);
      }

      &::after {
        opacity: 1;
      }
    }
  }

  &__meta {
    padding: 0 20px 0 0;
    width: calc(100% - 100px);

    @include media-query(tablet-portrait) {
      padding: 0 50px 0 40px;
      width: calc(100% - 150px);
    }

    @include media-query(tablet-landscape) {
      width: calc(100% - 300px);
    }
  }

  &__reading-col {
    max-width: 640px;

    @include media-query(desktop) {
      max-width: 680px;
    }

    @include media-query(desktop-wide) {
      max-width: 800px;
    }
  }

  &__label {
    color: $color--dark-grey-70;
    font-size: 11px;
    letter-spacing: 0.2em;
    line-height: 1.4;
    margin: 0 0 5px;
    text-transform: uppercase;
  }

  &__title, &__title p {
    font-size: 20px;
    font-weight: $weight--normal;
    line-height: 1.3;
    transition: color 0.2s ease;

    @include media-query(tablet-portrait) {
      font-size: 24px;
      font-weight: $weight--bold;
    }

    @include media-query(tablet-landscape) {
      font-size: 24px;
      margin: 0;
    }

    #{$root}:hover & {
      color: $color--purple;
    }
  }

  &__intro {
    color: $color--dark-grey-80;
    display: none;
    font-size: 16px;
    font-weight: $weight--light;
    line-height: 1.5;
    margin: 10px 0 0;

    @include media-query(tablet-portrait) {
      display: block;
    }
  }

  &__image {
    position: relative;
    width: 100px;
    height: fit-content;

    @include media-query(tablet-portrait) {
      width: 150px;
    }

    > video {
      object-fit: cover;
      width: 100%;
      height: auto;
    }

    // Overlay
    &::after {
      @include teal-overlay;

      @include media-query(tablet-portrait) {
        height: 150px;
        width: 150px;
      }
    }
  }
}
