// Container stream block
.home-features {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
}

// Individual feature blocks
.home-feature {
    display: flex;
    padding: var(--size-layout-heading-spacing, 110px) var(--size-layout-gutter, 140px);
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

        //notch
        &::before {
            background-color: var(--color-white);
            content: '';
            height: var(--size-notch);
            position: absolute;
            left: 0;
            bottom: 0;
            width: 65%;
        }

    &--image {
        display: grid;
        grid-template-columns: minmax(0%, 40%) minmax(0%, 60%);
        grid-gap: var(--size-80, 80px);

        &-left {
            grid-template-columns: minmax(0%, 60%) minmax(0%, 40%);
        }

        @media (max-width: $sw-desktop) {
            grid-template-columns: 100%;
        }
    }

    &--text {
        display: grid;
        grid-template-columns: minmax(0, 40%) minmax(0, 60%);
        grid-gap: var(--size-80, 80px);

        @media (max-width: $sw-tablet-landscape) {
            grid-gap: var(--size-80, 80px);
            grid-template-columns: unset;
            justify-content: unset;
            align-items: unset;
        }
    }

    &__column {
        width: 100%;

        &--image {
            @media (max-width: $sw-tablet-landscape) {
                width: 100%;
                justify-self: center;

                &:nth-child(even) {
                    justify-self: center;
                    justify-content: center;
                }
            }

            @media (max-width: $sw-desktop) {
                width: 80%;
                justify-self: flex-start;

                &:nth-child(even) {
                    display: flex;
                    justify-self: right;
                    justify-content: flex-end;
                }
            }
        }
    }

    // With-Text components
    &__main-copy,
    &__links {
        background-color: var(--white, #FFF);
    }

    &__text-image {
        &--desktop {
            display: block;

            @media (max-width: $sw-tablet-landscape) {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @media (max-width: $sw-tablet-landscape) {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    &__main-copy {
        position: relative;
        font-family: $font--primary;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 180%; // 43.2px
        margin-bottom: var(--size-80, 80px);
        padding: 25px 25px 25px 25px;
        width: fit-content;
        max-width: 640px;
        margin-top: 30px;

        @media (max-width: $sw-tablet-landscape) {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 20px;
        }

        &--desktop {
            display: block;

            @media (max-width: $sw-tablet-landscape) {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @media (max-width: $sw-tablet-landscape) {
                display: block;
            }
        }

        .rich-text {
            margin: 0;
            outline: 1px solid var(--black, #000);
            outline-offset: 34px;

            /* Clears outline to make space for notch */
            clip-path:
                polygon(
                    -40px -40px,
                    calc(100% + 40px) -40px,
                    calc(100% + 40px) calc(100% + 40px),
                    calc(45% + 7px) calc(100% + 40px),
                    calc(45% + 10px) 100%,
                    -40px calc(100% + 34px)
                );

            p {
                margin: 0;
            }
        }

        // Notch
        &::after {
            content: '';
            width: 45%;
            height: 20px;
            background-color: var(--white, #FFF);
            position: absolute;
            bottom: -19px;
            left: 0;
            outline: 1px solid var(--black, #000);
            outline-offset: 9px;
            clip-path:
                polygon(
                    -10px 0,
                    calc(100% + 10px) 0,
                    100% 0,
                    calc(100% + 10px) 10px,
                    calc(100% + 10px) calc(100% + 10px),
                    -10px calc(100% + 10px)
                );
        }
    }

    &__ctas {
        display: flex;

        &::after {
            content: '';
            width: 20px;
            height: 99px;
            max-height: 80%;
            background-color: var(--white, #FFF);
            outline: 1px solid var(--black, #000);
            outline-offset: 9px;
            margin-left: -1px;
            clip-path:
                polygon(
                    0 -10px,
                    calc(100% + 10px) -10px,
                    calc(100% + 10px) calc(100% + 10px),
                    11px calc(100% + 10px),
                    0 100%
                );
        }
    }

    &__links {
        padding: 25px;
        display: flex;
        flex-direction: column;
        width: fit-content;
        max-width: 50%;
        position: relative;
        outline: 1px solid var(--black, #000);
        outline-offset: 9px;
        clip-path:
            polygon(
                -10px -10px,
                100% -10px,
                100% 90px,
                calc(100% + 10px) 110px,
                calc(100% + 10px) calc(100% + 10px),
                -10px calc(100% + 10px)
            );
    }


    &__link-container {
        display: inline-block;
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        font-family: $font--primary;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; //28px
        text-transform: uppercase;
        border-bottom: 2px solid $color--teal;

        @media (max-width: $sw-tablet-landscape) {
            font-size: 16px;
        }

        &:hover {
            border-color: var(--purple, $color--purple);
        }
    }
}
