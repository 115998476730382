.program-hero {
  background-color: #FFF;
  overflow: hidden;
  padding: 0 0 25px;

  @include media-query(tablet-landscape) {
    display: flex;
  }

  &__left {
    position: relative;

    @include media-query(tablet-landscape) {
      width: 60%;
    }

    img,
    video {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    &--with-video {
      display: block;
      @include media-query(tablet-landscape) {
        display: none;
      }
    }
  }

  &__media {
    display: none;
    @include media-query(tablet-landscape) {
      display: block;
    }
  }

  &__right {
    margin-top: 20px;
    position: relative;
    z-index: 1;

    @include media-query(tablet-landscape) {
      margin-top: 0;
      padding: 130px 60px 0;
      width: 40%;
    }
  }

  &__heading {
    background-color: #FFF;
    margin: 0 20px;
    padding: 10px 0;
    position: relative;
    z-index: 2;

    @include media-query(tablet-portrait) {
      margin: 0 80px;
    }

    @include media-query(tablet-landscape) {
      margin: 0 0 0 -310px;
      padding: 30px;
    }

    h1 {
      margin: 0;
      padding: 0;

      @include media-query(tablet-portrait) {
        font-size: 50px;
        line-height: 60px;
      }

      @include media-query(tablet-landscape) {
        font-size: 60px;
        line-height: 70px;
      }

      > span {
        color: $color--navy;
        font-weight: $weight--light;
        margin-right: 10px;
      }
    }
  }

  &__content {
    background-color: #FFF;
    margin: 0 20px;
    position: relative;

    @include media-query(tablet-portrait) {
      margin: 0 80px;
    }

    @include media-query(tablet-landscape) {
      margin: 0 0 0 -240px;
      padding: 0 40px 30px;
    }

    @include media-query(desktop) {
      max-width: 100%;
    }

    p {
      font-family: $font--primary;
      font-size: 20px;
      line-height: 1.5;
      margin: 0;

      @include media-query(tablet-landscape) {
        font-size: 24px;
      }
    }
  }

  &__angle-background {
    background-color: #FFF;
    bottom: -70px;
    height: 600px;
    padding: 25px 0 0;
    position: absolute;
    right: -200px;
    transform: rotate(25deg);
    width: 200px;

    @include media-query(tablet-landscape) {
      right: -190px;
    }
  }

  &__angle-line {
    background-color: $color--dark-grey;
    bottom: -50px;
    height: 100%;
    left: 0;
    position: absolute;
    width: 1px;

    @include media-query(tablet-portrait) {
      height: 90%;
    }

    @include media-query(tablet-landscape) {
      height: 98%;
    }

    @include media-query(desktop) {
      height: 115%;
    }

    @include media-query(desktop-wide) {
      height: 135%;
    }
  }

  &__caption {
    bottom: 10px;
    display: inline-block;
    left: 10px;
    margin: 0;
    position: absolute;
    z-index: 1;
  }
}
