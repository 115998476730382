.crosshair {
    border-bottom: 1px solid $color--dark-grey;
    left: -120px;
    position: absolute;
    width: 200px;
    z-index: 1;

    @include media-query(tablet-portrait) {
        left: 0;
    }

    &::after {
        background-color: $color--dark-grey;
        content: '';
        height: 50px;
        left: 28%;
        position: absolute;
        top: 15px;
        width: 1px;
    }

    &::before {
        background: $color--white;
        content: '';
        height: 30px;
        left: 20%;
        position: absolute;
        top: -15px;
        width: 30px;
    }

    &--right {
        display: none;
        left: auto;
        right: -130px;

        @include media-query(tablet-portrait) {
            display: block;
        }

        @include media-query(tablet-landscape) {
            right: 0;
        }

        &::after {
            left: 68%;
        }

        &::before {
            left: 60%;
        }
    }

    &--story {
        width: 175px;

        &::after {
            left: 35.5%;
        }

        &::before {
            left: 27%;
        }
    }

    &--search-results {
        right: -150px;
        top: 240px;

        @include media-query(desktop) {
            right: 0;
            top: 270px;
        }
    }

    &--newsroom {
        margin-top: -1px;
    }
}

.crosshair-story {
    margin-bottom: 45px;
}
