// CCA colours
$color--white: #FFF;
$color--black: #141414;
$color--true-black: #000;

// Greys
$color--dark-grey: #222;
$color--med-grey: #C3C5C6;
$color--cool-grey: #6A737C;
$color--cool-grey-med: #888B8D;
$color--light-grey: #EAEAEA;
$color--very-light-grey: #F9F9F9;

// Brand colors
$color--teal: #00BFB3;
$color--teal-tint: #BFEFEC;
$color--purple: #563D82;
$color--blue: #007096;
$color--navy: #005776;
$color--sand: #EFEEE6;
$color--gold: #FFC845;
$color--red: #CF4520;
$color--beige-tint: #F7F3E9;
$color--yellow: #F8E55A;

// Named color types
$color--default: $color--black;

$color--primary: $color--cool-grey;
$color--secondary: $color--light-grey;
$color--tertiary: $color--very-light-grey;

// Button colours
$button-colours: (
    primary: (bg: $color--primary, color: #FFF),
    secondary: (bg: $color--secondary, color: #000),
    tertiary: (bg: $color--tertiary, color: #000),
);

// Transparent
$color--dark-grey-30: rgba(34, 34, 34, 0.3);
$color--dark-grey-50: rgba(34, 34, 34, 0.5);
$color--dark-grey-70: rgba(34, 34, 34, 0.7);
$color--dark-grey-80: rgba(34, 34, 34, 0.8);
$color--white-95: rgba(255, 255, 255, 0.95);

$shadow--pale: #EAF0F6;

// Streamfield section colours
$sf-section-colors: (
    'sand': $color--sand
);

// Streamfield notch height
$sf-section-notch: 20px;

// Fonts
$font--primary: 'lineto', arial, sans-serif;
$font--secondary: 'merriweather', arial, sans-serif;
$font--mono: 'roboto-mono', monaco, monospace;
$font--chimera: 'chimera-display', arial, sans-serif;

// Font weights
$weight--bold: 700;
$weight--normal: 400;
$weight--light: 300;

// Font sizes
$base-font-size: 16px;
$base-line-height: 25px;
$font-sizes: (
    /* non-standard large sizes*/
    giga: 80px,
    mega: 60px,
    kilo: 48px,
    /* standard sizes*/
    alpha: 38px,
    beta: 30px,
    gamma: 24px,
    delta: 20px,
    epsilon: 18px,
    zeta: 16px,
    /* non-standard small sizes*/
    milli: 14px
);

// Wrappers
$site-width: 1280px;
$wrapper--small: 790px;

// Breakpoints
$breakpoints: (
    'tablet-portrait'    '(min-width: 768px)',
    'tablet-landscape'   '(min-width: 1024px)',
    'desktop'            '(min-width: 1280px)',
    'desktop-mid'        '(min-width: 1420px)',
    'desktop-wide'       '(min-width: 1500px)',
    'desktop-extra-wide' '(min-width: 1720px)'
);

// Transition speeds
$transition: 0.25s ease-out;
$quick-transition: 0.15s ease-out;

// Angles
$tilt-right: 25deg;

// Breakpoints as Variables
$sw-mobile: 640px;
$sw-tablet-portrait: 768px;
$sw-tablet-landscape: 1024px;
$sw-desktop: 1280px;
$sw-desktop-mid: 1420px;
$sw-desktop-wide: 1500px;
$sw-desktop-extra-wide: 1720px;
