.sf-cta-large {
    @include media-query(tablet-portrait) {
        align-items: flex-start;
        display: flex;
    }

    &__text {
        background-color: $color--teal;
        font-family: $font--primary;
        font-size: 26px;
        font-weight: $weight--normal;
        line-height: 1.2;
        padding: 30px 20px 40px;
        position: relative;

        @include media-query(tablet-portrait) {
            align-self: flex-start;
            font-size: 32px;
            padding: 40px 100px 40px 0;
            width: 65%;
        }

        &::before {
            background-color: $color--teal;
            bottom: 0;
            content: '';
            height: 100%;
            left: -400px;
            position: absolute;
            width: 400px;
        }

        p {
            margin: 0;
        }
    }

    &__link {
        background-color: $color--dark-grey;
        color: $color--white;
        display: block;
        font-family: $font--primary;
        font-size: 26px;
        font-weight: $weight--light;
        line-height: 1.2;
        margin-top: -10px;
        padding: 20px;
        position: relative;
        transition: none;

        &:hover {
            color: $color--white;
        }

        @include media-query(tablet-portrait) {
            font-size: 32px;
            margin: 15px 0 0;
            padding: 25px 25px 50px 0;
            transition: $transition;
            width: 35%;

            &:hover {
                color: $color--white;

                &,
                &::before {
                    background-color: $color--purple;
                }

                > svg {
                    fill: $color--white;
                    transform: translateX(20px);
                }
            }
        }

        &::before {
            @include media-query(tablet-portrait) {
                background-color: $color--dark-grey;
                content: '';
                height: 80%;
                left: -60px;
                position: absolute;
                top: 0;
                transition: $transition;
                width: 60px;
            }
        }

        > svg {
            bottom: 20px;
            fill: $color--teal;
            height: 28px;
            position: absolute;
            right: 20px;
            transition: $transition;
            width: 50px;

            @include media-query(tablet-portrait) {
                right: 40px;
            }
        }
    }
}
