// lightGallery overrides
.lg-backdrop {
  background-color: $color--white-95;
}

// meta data parent
.lg-sub-html {
  background-color: $color--white-95;
  border-top: 1px solid $color--black;
  min-height: 180px;
  padding: 40px 40px 20px;
  text-align: left;

  @include media-query(tablet-portrait) {
    min-height: 160px;
    padding: 40px 65px 0;
  }

  @include media-query(tablet-landscape) {
    min-height: 105px;
    padding: 15px 100px;
  }
}

// top toolbar
.lg-toolbar {
  background-color: $color--white-95;

  .lg-hide-items & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// close icon
.lg-close {
  width: 40px !important;
  height: 40px !important;
  position: absolute;
  right: 40px;
  top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition;

  &::after {
    display: block;
    content: '' !important;
    @include icon;
    @include X-icon;

    background-color: $color--black;
    height: 20px;
    width: 20px;
  }

  &:hover {
    cursor: pointer;
    background-color: $color--teal;
  }
}

.lg-next,
.lg-prev {
  background-color: transparent;
  bottom: -100px;
  top: auto;
  z-index: 1081;

  @include media-query(tablet-portrait) {
    bottom: -100px;
  }

  @include media-query(tablet-landscape) {
    bottom: auto;
    top: calc(50% - 35px);
  }

  &::before,
  &::after {
    display: none;
  }

  .lg-hide-items & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.lg-prev {
  left: 0;

  @include media-query(tablet-portrait) {
    left: 20px;
  }
}

.lg-next {
  right: 0;

  @include media-query(tablet-portrait) {
    right: 20px;
  }
}

.lg-outer {
  .lg-item {
    background-position: center 35%;

    @include media-query(tablet-landscape) {
      background-position: center 43%;
    }
  }

  .lg-img-wrap {
    padding: 12px;

    @include media-query(tablet-landscape) {
      padding: 12px;
    }
  }

  .lg-video-object {
    width: 100%;
    height: 100%;
  }

  .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
  }
}

.lg-video-cont {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) !important;
  -moz-transform: translateX(-50%) translateY(-50%) !important;
  -ms-transform: translateX(-50%) translateY(-50%) !important;
  -o-transform: translateX(-50%) translateY(-50%) !important;
  transform: translateX(-50%) translateY(-50%) !important;
  z-index: 100;
  padding: 12px;
  @include media-query(tablet-portrait) {
    padding: 36px 140px 12px 140px;
  }
  @include media-query(desktop) {
    padding: 36px 75px 12px 75px;
  }
}

.lg-counter {
  //sass-lint:disable-line no-ids
  color: $color--black;
  font-family: $font--primary;
  font-weight: $weight--normal;
  padding: 0;
  position: absolute;
  bottom: -60px;
  left: 40px;
  z-index: 1081;

  // Hide duplicate counter, if exists.
  &:nth-of-type(3) {
    display: none;
  }

  @include media-query(tablet-portrait) {
    bottom: -60px;
    left: 65px;
  }

  @include media-query(tablet-landscape) {
    bottom: -85px;
    left: 100px;
    width: 5%;
  }
}

.lg-counter-all,
.lg-counter-current {
  color: $color--black;
}

.lg-counter-current {
  &::after {
    content: " of";
  }
}

// Hide default play button
.lg-video-play-button {
  display: none;
}
