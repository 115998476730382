.header {
    background-color: $color--white;
    position: relative;
    z-index: 20;

    &--simplified {
        padding: 10px 0 !important;
        position: fixed;
        width: 100%;
    }

    .headroom & {
        position: fixed;
        top: 0;
        transition: transform 0.2s ease-in;
        width: 100%;
    }

    .headroom--pinned & {
        transform: translateY(0%);
    }

    .headroom--unpinned & {
        transform: translateY(-100%);
    }

    @include media-query(tablet-landscape) {
        padding: 0;
    }

    &__inner {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;

        @include media-query(tablet-landscape) {
            padding: 0 20px;
        }
    }

    &__inner--top {
        align-items: center;
        display: none;
        justify-content: flex-end;
    }

    &__menus {
        &--desktop {
            display: none;

            @include media-query(tablet-landscape) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
            }
        }

        &--mobile {
            background: rgba($color--white, 0.95);
            left: 0;
            position: fixed;
            top: 0;
            transform: translateY(-100%);
            transition: transform 250ms cubic-bezier(0.24, 0.26, 0.2, 1) 0ms;
            width: 100%;
            z-index: 100;

            .nav--primary {
                margin-top: 62px;
            }

            @include media-query(tablet-landscape) {
                display: none;
            }

            &.is-visible {
                top: 70px;
                transform: translate3d(0%, 0%, 0);
                height: 100%;
            }

            nav {
                width: 100%;
            }
        }
    }

    &__logo {
        display: none;
        fill: $color--teal;

        @include media-query(tablet-portrait) {
            display: block;
            height: 40px;
            margin-left: 10px;
            width: 220px;
        }

        &--mobile {
            display: block;
            height: 40px;
            width: 90px;

            @include media-query(tablet-portrait) {
                display: none;
            }
        }

        &--home {
            align-items: flex-end;
            display: flex;
            left: 20px;
            position: fixed;
            top: 18px;
            z-index: 1;

            @include media-query(tablet-portrait) {
                top: 15px;
            }

            svg {
                fill: $color--white;

                &:first-child {
                    height: 34px;
                    width: 90px;

                    @include media-query(tablet-portrait) {
                        height: 40px;
                        width: 104px;
                    }
                }

                &:last-child {
                    display: none;
                    height: 30px;
                    margin-left: 12px;
                    width: 104px;

                    @include media-query(tablet-portrait) {
                        display: block;
                    }
                }
            }
        }
    }

    &__menu-toggle {
        @include font-size(milli);

        align-items: flex-start;
        color: $color--secondary;
        display: flex;
        flex-direction: row-reverse;
        font-weight: $weight--bold;
        text-transform: uppercase;
        z-index: 10;

        @include media-query(tablet-landscape) {
            display: none;
        }
    }
}
