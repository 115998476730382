.caption {
  padding: 5px 20px 0 0;

  &--full-width {
    margin: 0 20px;
  }

  &--media-block {
    @include media-query(tablet-landscape) {
      width: 50%;
      display: block;
      margin: 0 auto;
    }
  }

  p {
    @include font-size(milli);

    color: rgba(34, 34, 34, 0.7);
    font-family: $font--primary;
    font-weight: $weight--light;
    line-height: 1.3;
    margin: 5px 0 0;
  }

  &--overlayed {
    p {
      color: $color--white;
      text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    }
  }

  .rich-text a {
    font-size: inherit;
    font-weight: inherit;
  }
}
