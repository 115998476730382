.search-results {
  margin-top: 120px;

  &__form {
    margin-bottom: 40px;
    width: 100%;

    @include media-query(tablet-portrait) {
      margin-bottom: 80px;
      width: 72%;
    }
  }

  &__input-wrapper {
    align-items: center;
    border-bottom: 1px solid $color--dark-grey;
    display: flex;
    position: relative;
    width: 100%;
  }

  &__input {
    @include placeholder-text {
      color: rgba(0, 0, 0, 0.65);
    }

    background-color: transparent;
    border: 0;
    color: $color--dark-grey;
    flex: 1;
    font-family: $font--primary;
    font-size: 22px;
    font-weight: $weight--bold;
    height: 50px;
    padding-right: 45px;
    width: 100%;

    @include media-query(desktop) {
      font-size: 50px;
      height: 80px;
    }

    &::-ms-clear { // sass-lint:disable-line no-vendor-prefixes
      display: none;
    }
  }

  &__label {
    @include hidden;
  }

  &__clear {
    background: transparent;
    border: 0;
    color: $color--navy;
    cursor: pointer;
    display: none;
    font-family: $font--primary;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    padding: 0;

    svg {
      fill: $color--navy;
      height: 14px;
      vertical-align: -2px;
      width: 14px;
    }

    &:hover {
      color: $color--purple;

      svg {
        fill: $color--purple;
      }
    }
  }

  &__count {
    color: $color--navy;
    display: inline-block;
    font-family: $font--primary;
    font-size: 18px;
    font-weight: $weight--bold;
    line-height: 1.3;
    margin: 0 0 20px;

    @include media-query(tablet-portrait) {
      font-size: 20px;
    }

    @include media-query(desktop) {
      margin: 0 0 30px;
    }

    &--padded-left {
      @include media-query(tablet-portrait) {
        padding-left: 15px;
      }
    }
  }

  &__help-text {
    max-width: 640px;

    @include media-query(desktop) {
      max-width: 680px;
    }
  }
}
