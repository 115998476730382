.alert-banner {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;

    //Fonts
    font-family: $font--primary;
    font-weight: 400;
    line-height: 24px;

    &__icon {
        height: 24px;
        width: 24px;
        background-color: $color--white;
        margin-right: 8px;
        float: left;

        //mask
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-position: center;
        mask-size: contain;
        -webkit-mask-size: contain;

        &--alert {
            -webkit-mask-image: url("~images/exclamation-circle.svg");
            mask-image: url("~images/exclamation-circle.svg");
        }

        &--notification {
            background-color: $color--true-black;
            -webkit-mask-image: url("~images/info-circle-outline.svg");
            mask-image: url("~images/info-circle-outline.svg");
        }
    }

    &__header {
        text-transform: uppercase;
    }

    a {
        color: $color--white;
        text-decoration: underline;
        font-weight: 400;
    }

    &--alert {
        background-color: #E00;
        color: $color--white;

        a {
            color: $color--white;

            &:hover {
                color: $color--white;
            }
        }
    }

    &--notification {
        background-color: $color--gold;
        color: $color--true-black;

        a {
            &:hover {
                color: $color--true-black;
            }

            color: $color--true-black;
        }
    }

    p {
        margin: 0;
        margin-right: 8px;
        display: inline;
    }

    b {
        font-weight: 600;
    }
}
