.animated-single-number {
    text-align: center;
    font-family: $font--primary;
    padding: 40px 0;

    &__title {
        font-weight: 600;
        text-transform: uppercase;
    }

    &__number-container {
        display: flex;
        justify-content: center;
        min-height: 8rem;

        @media (max-width: $sw-tablet-portrait) {
            min-height: 4rem;
        }
    }

    &__number {
        color: $color--teal;
        font-size: 8rem;
        line-height: 8rem;
        font-family: $font--mono;

        @media (max-width: $sw-tablet-portrait) {
            line-height: 4rem;
            font-size: 4rem;
        }
    }

    &__dollar-sign {
        color: $color--teal;
        font-size: 5rem;
        font-weight: 500;
        margin-top: -35px;

        @media (max-width: $sw-tablet-portrait) {
            font-size: 2.5rem;
            margin-top: -15px;
        }
    }

    &__caption {
        .rich-text {
            max-width: 700px;
            margin: 0 auto;
            font-size: 1.3rem;

            p {
                line-height: 1.3;
            }
        }
    }
}

.animated-number-grid {
    background-color: $color--teal;
    display: grid;
    justify-content: center;
    margin: 40px 0;
    position: relative;

    &__items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 20px;

        @media (max-width: $sw-tablet-landscape) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: $sw-tablet-portrait) {
            grid-template-columns: repeat(auto-fill, 290px);
        }
    }

    &__notch {
        height: 25px;
        width: 176px;
        background-color: $color--teal;
        position: absolute;
        top: -24px;

        @media (max-width: $sw-tablet-portrait) {
            display: none;
        }

        &--left {
            left: 0;
        }

        &--right {
            right: 0;
        }
    }
}

.animated-number-grid-item {
    color: $color--white;
    background-color: $color--teal;
    font-family: $font--primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    height: 250px;
    width: 290px;

    &__title {
        color: $color--black;
        font-size: 1.5rem;
        line-height: 1;
        margin-top: 16px;
        text-align: center;
        font-weight: 600;
    }

    &__dollar-sign {
        font-size: 2.25rem;
        line-height: 2rem;
        margin: 0;
        font-weight: 600;
    }

    &__number {
        font-size: 2.25rem;
        line-height: 2rem;
        margin: 0;
        font-family: $font--mono;
    }

    &__number-container {
        display: flex;
        align-items: center;
    }
}
