.sf-section {
    $root: &;

    padding-bottom: 40px;

    // remove styles when sf's are nested
    .sf-section & {
        padding-bottom: 0;
    }

    // Base wrapper
    &__wrapper {
        @extend %container;

        &--table {
            padding: 0 0 0 20px;

            @include media-query(tablet-portrait) {
                padding: 0 80px;
            }

            @include media-query(tablet-landscape) {
                padding: 0 130px;
            }

            @include media-query(desktop) {
                padding: 0 140px;
            }

            @include media-query(desktop-wide) {
                padding: 0 200px;
            }
        }

        // remove styles when sf's are nested
        .container &,
        .sf-section__wrapper & {
            padding: 0;
        }

        // don't squash page titles
        .info-header__wrapper & {
            @include media-query(tablet-landscape) {
                padding-right: 100px;
            }
        }
    }

    // Reading column
    &__restricted-column {
        max-width: 640px;

        @include media-query(desktop) {
            max-width: 680px;
        }

        @include media-query(desktop-wide) {
            max-width: 800px;
        }
    }

    &__subheading {
        color: $color--navy;
    }

    &__heading {
        font-weight: $weight--normal;
    }

    &__heading-link {
        @include font-size(zeta);

        color: $color--dark-grey-80;
        display: inline-block;
        font-family: $font--primary;
        margin: 0 0 20px 35px;

        &:hover {
            color: $color--teal;
        }
    }

    &__teaser {
        color: $color--dark-grey-80;
        font-family: $font--primary;
        font-size: 20px;
        font-weight: $weight--light;
        line-height: 1.5;
        margin: 10px 0;

        @include media-query(tablet-landscape) {
            font-size: 24px;
        }
    }

    // Colour modifiers
    @each $label, $color in $sf-section-colors {
        &--#{$label} {
            background-color: #{$color};
        }
    }

    &--overflow-hidden {
        overflow: hidden;
    }

    &--notch {
        margin-top: 50px;
        padding: 50px 0 60px;
        position: relative;

        &::before {
            background-color: $color--white;
            content: '';
            height: $sf-section-notch;
            position: absolute;
            right: 0;
            top: 0;
            width: 65%;
        }

        h2 {
            @include media-query(tablet-portrait) {
                font-size: 32px;
            }
        }
    }

    &--curriculum {
        margin-top: 0;
        padding: 50px 20px 60px;

        @include media-query(tablet-portrait) {
            padding: 60px 50px;
        }

        // bottom notch
        &::after {
            background-color: $color--sand;
            bottom: -#{$sf-section-notch};
            content: '';
            height: $sf-section-notch;
            position: absolute;
            right: 0;
            width: 140px;
        }
    }

    &--divisions {
        margin: 65px 0 40px;
        padding: 50px 0 15px;
        position: relative;

        @include media-query(tablet-landscape) {
            margin: 70px 0 40px;
        }

        // top notch
        &::before {
            background-color: $color--sand;
            content: '';
            height: $sf-section-notch;
            position: absolute;
            right: 0;
            top: -#{$sf-section-notch};
            width: 15%;
        }

        // bottom notch
        &::after {
            background-color: $color--sand;
            bottom: -#{$sf-section-notch};
            content: '';
            height: $sf-section-notch;
            left: 0;
            position: absolute;
            width: 35%;
        }

        h3 {
            font-size: 20px;
            font-weight: $weight--normal;
            line-height: 1.3;
            margin: 0 0 15px;

            @include media-query(tablet-portrait) {
                font-size: 24px;
            }
        }
    }

    &--gallery {
        margin: 0 0 40px;
        padding: 60px 0;

        h2 {
            font-size: 26px;

            @include media-query(tablet-portrait) {
                font-size: 36px;
            }

            @include media-query(desktop-wide) {
                font-size: 38px;
            }
        }
    }

    &--table,
    &--accordion,
    &--logo-block,
    &--feature-multiple,
    &--instagram-feed,
    &--latest-newsroom-items {
        h3 {
            line-height: 1.3;

            @include media-query(tablet-landscape) {
                font-size: 24px;
            }
        }
    }

    &--table,
    &--accordion {
        h3 {
            font-weight: $weight--normal;
        }
    }

    &--footer-sibling {
        margin-bottom: -55px;
    }

    &--related-programs {
        padding: 50px 0 35px;
    }

    &--flat {
        padding: 0;
    }

    &--person-snippet {
        @extend %container;

        display: grid;
        grid-gap: 20px;

        @include media-query(tablet-portrait) {
            grid-gap: 40px;
            grid-template-columns: 1fr 1fr;
            padding: 0 80px;
        }

        @include media-query(tablet-landscape) {
            padding: 0 130px 40px;
        }

        @include media-query(desktop) {
            padding: 0 140px 40px;
        }

        @include media-query(desktop-wide) {
            grid-template-columns: 1fr 1fr 1fr;
            padding: 0 200px 40px;
        }
    }

    &--extra-space {
        @include media-query(tablet-portrait) {
            padding-bottom: 80px;
        }

        @include media-query(desktop-extra-wide) {
            padding-bottom: 140px;
        }
    }
}

.sf-notch-wrapper {
    background-color: $color--sand;
    margin: 5px 0 50px;
    padding: 50px 0 20px;
    position: relative;

    &::before {
        background-color: inherit;
        bottom: -$sf-section-notch;
        content: '';
        height: $sf-section-notch;
        left: 0;
        position: absolute;
        width: 35%;
    }

    &--no-top-padding {
        padding-top: 0;
    }
}
