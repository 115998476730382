.info-header {
  $root: &;

  &--with-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: calc(70vh - 70px);
    position: relative;

    @include media-query(tablet-portrait) {
      padding-bottom: calc(70vh - 60px);
    }
  }

  &__image {
    &--with-video {
      display: block;
      @include media-query(tablet-landscape) {
        display: none;
      }
    }
  }

  &--no-image {
    // alternate styling for intro text with no image
    + .sf-section {
      background-color: $color--teal;
      color: black;
      margin: 20px 0 30px;
      padding: 30px 0 60px;
      position: relative;

      @include media-query(tablet-landscape) {
        width: 70%;
      }

      &::after {
        background-color: $color--white;
        bottom: 0;
        content: "";
        height: $sf-section-notch;
        position: absolute;
        right: 0;
        width: 30%;
      }

      // .sf-intro {
      //     color: $color--white;
      // }
    }
  }

  &__caption {
    bottom: 35px;
    display: inline-block;
    left: 10px;
    margin: 0;
    position: absolute;
    z-index: 1;

    .template-index-page &,
    .template-academics-page &,
    .template-admissions-page & {
      bottom: 50px;
    }
  }

  &__title-wrapper {
    #{$root}__wrapper--with-image & {
      background-color: $color--white;
    }
  }

  &__title {
    margin: 0;
    padding-top: 10px;
  }

  &__wrapper {
    &--with-image {
      background-color: $color--white;
      margin-top: -15px;
      padding: 20px 0 10px;
      position: relative;
      width: 80%;

      @include media-query(tablet-portrait) {
        margin-top: -30px;
        padding: 30px 0 20px;
        width: 70%;
      }

      &::before {
        background-color: $color--dark-grey;
        content: "";
        display: none;
        height: 300px;
        position: absolute;
        right: -200px;
        top: -50px;
        transform: rotate($tilt-right);
        width: 1px;

        @include media-query(tablet-landscape) {
          display: block;
        }
      }
    }

    &--with-video {
      margin-top: -130px;
      @include media-query(tablet-landscape) {
        margin-top: -30px;
      }
    }

    &--no-image {
      padding-top: 60px;

      @include media-query(tablet-portrait) {
        padding-top: 90px;
      }
    }

    &--video-fallback {
      background: $color--teal;
      display: inline-block;
      margin: -25px 0 50px;
      padding: 0;
      width: auto;

      &::before {
        display: none;
      }

      &::after {
        background-color: $color--teal;
        content: "";
        height: 20px;
        left: 0;
        position: absolute;
        top: -20px;
        width: 75%;
      }

      #{$root}__title {
        background-color: $color--teal;
        color: $color--black;
        font-size: 50px;
        font-weight: $weight--normal;
        margin-bottom: 1rem;

        @include media-query(tablet-portrait) {
          font-size: 70px;
          letter-spacing: -0.04em;
          line-height: 80px;
        }

        @include media-query(desktop) {
          font-size: 80px;
          line-height: 90px;
        }
      }
    }
  }
}
