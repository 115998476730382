.animated-text {
    &__item {
        text-wrap: nowrap;
        display: flex;
    }
}

.buzzword {
    position: absolute;
    margin: auto;
    font-size: 0;

    &-letter {
        opacity: 0;
        font-family: $font--chimera;
        color: var(--black, #222);
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 75px */
        letter-spacing: 2px;
        text-transform: uppercase;

        &--space {
            display: inline-block;
            width: 16px;
        }

        @media (max-width: $sw-tablet-landscape) {
            font-size: 40px;
        }

        @media (max-width: $sw-tablet-portrait) {
            font-size: 24px;
        }
    }
}
