/* ------------------------------------ *\
  $MIXINS
\* ------------------------------------ */

/* ============================================
  Media queries
*/
@mixin media-query($queries...) {
    @each $query in $queries {
        @each $breakpoint in $breakpoints {
            $name: nth($breakpoint, 1);
            $declaration: nth($breakpoint, 2);

            @if $query == $name and $declaration {
                @media only screen and #{$declaration} {
                    @content;
                }
            }
        }
    }
}

/* ============================================
  Placeholder text
*/
@mixin placeholder-text {
    // sass-lint:disable no-vendor-prefixes
    &.placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
    // sass-lint:enddisable
}

/* ============================================
  Hide text
*/
@mixin hidden {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
}

/* ============================================
  iOS Native vertical scroll
*/
@mixin native-vertical-scroll {
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties
    overflow-x: hidden;
    overflow-y: scroll;
}

/* ============================================
  Output a rem and px fallback value for the given property
*/
@mixin rem($property, $values) {
    $px: ();
    $rem: ();

    @each $value in $values {
        @if $value == 0 or $value == auto or $value == inherit {
            $px: append($px, $value);
            $rem: append($rem, $value);
        } @else {
            $px: append($px, $value);
            $rem: append($rem, rem(strip-unit($value)));
        }
    }
    // sass-lint:disable no-duplicate-properties
    #{$property}: $px;
    #{$property}: $rem;
    // sass-lint:enddisable
}

/* ============================================
  Output a `font-size: [x]rem;` declaration for the given px value
*/
@mixin rem-font-size($font-size) {
    @include rem(font-size, $font-size);
}

/* ============================================
  Font sizes
*/

@mixin font-size($keyword) {
    $size: map-get($font-sizes, $keyword);

    @if $size == null {
        @warn 'Font size ‘#{$keyword}’ does not exist';
    } @else {
        font-size: $size;
    }
}

/* ============================================
  Mix blend mode fallback (IE11)
  Use on teal overlays
*/

@mixin mix-blend-mode-fallback {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background-color: rgba(0, 190, 178, 0.8);
    }

    @supports not (mix-blend-mode: multiply) {
        background-color: rgba(0, 190, 178, 0.8);
    }
}

// Teal Overlay for Images
@mixin teal-overlay {
    @include mix-blend-mode-fallback;

    background-color: $color--teal;
    content: "";
    height: 100%;
    left: 0;
    mix-blend-mode: multiply;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity $transition;
    width: 100%;
    z-index: 2;
}

// Header slash
@mixin header-slash {
    background-color: $color--dark-grey;
    content: "";
    height: 90px;
    left: -20px;
    position: absolute;
    top: 0;
    transform: rotate($tilt-right);
    width: 1px;

    @include media-query(tablet-portrait) {
        left: -30px;
        top: 90px;
    }
}

/* ============================================
  Text
  ============================================
*/
@mixin small-feature-title {
    @include font-size(delta);

    color: $color--default;
    display: inline;
    font-family: $font--primary;
    font-size: 20px;
    font-weight: $weight--bold;
    line-height: 1.4;
    transition: text-decoration-color $transition;
    text-decoration: underline;
    text-decoration-color: $color--teal;
    text-decoration-thickness: 1px;
    text-underline-offset: 6px;
}

@mixin link-border {
    border-bottom: 1px solid $color--teal;

    &:hover {
        border-color: $color--purple;
    }
}

/* ============================================
  Icons
*/

@mixin icon {
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    mask-size: contain;
    -webkit-mask-size: contain;
}

// Capital Campaign
@mixin award-icon {
    -webkit-mask-image: url("~images/icons/award.svg");
    mask-image: url("~images/icons/award.svg");
}

@mixin gift-icon {
    -webkit-mask-image: url("~images/icons/gift.svg");
    mask-image: url("~images/icons/gift.svg");
}

@mixin grad-cap-icon {
    -webkit-mask-image: url("~images/icons/grad-cap.svg");
    mask-image: url("~images/icons/grad-cap.svg");
}

@mixin people-icon {
    -webkit-mask-image: url("~images/icons/people.svg");
    mask-image: url("~images/icons/people.svg");
}

@mixin person-icon {
    -webkit-mask-image: url("~images/icons/person.svg");
    mask-image: url("~images/icons/person.svg");
}

@mixin puzzle-piece-icon {
    -webkit-mask-image: url("~images/icons/puzzle-piece.svg");
    mask-image: url("~images/icons/puzzle-piece.svg");
}

@mixin scholarship-icon {
    -webkit-mask-image: url("~images/icons/scholarship.svg");
    mask-image: url("~images/icons/scholarship.svg");
}

@mixin speaker-icon {
    -webkit-mask-image: url("~images/icons/speaker.svg");
    mask-image: url("~images/icons/speaker.svg");
}

@mixin arrow-down-icon {
    -webkit-mask-image: url("~images/icons/chevron-black.svg");
    mask-image: url("~images/icons/chevron-black.svg");
}

@mixin arrow-up-icon {
    -webkit-mask-image: url("~images/icons/chevron-black.svg");
    mask-image: url("~images/icons/chevron-black.svg");
    transform: rotate(0.5turn);
}

@mixin arrow-right-icon {
    -webkit-mask-image: url("~images/icons/chevron-black.svg");
    mask-image: url("~images/icons/chevron-black.svg");
    transform: rotate(-0.25turn);
}

@mixin arrow-left-icon {
    -webkit-mask-image: url("~images/icons/chevron-black.svg");
    mask-image: url("~images/icons/chevron-black.svg");
    transform: rotate(0.25turn);
}

@mixin X-icon {
    -webkit-mask-image: url("~images/icons/x.svg");
    mask-image: url("~images/icons/x.svg");
}

@mixin close-button-icon {
    -webkit-mask-image: url("~images/close_button_with_text.svg");
    mask-image: url("~images/close_button_with_text.svg");
}

@mixin external-link-icon {
    -webkit-mask-image: url("~images/icons/external-link-arrow.svg");
    mask-image: url("~images/icons/external-link-arrow.svg");
}

@mixin pause-icon {
    -webkit-mask-image: url("~images/icons/pause.svg");
    mask-image: url("~images/icons/pause.svg");
}

@mixin play-icon {
    -webkit-mask-image: url("~images/icons/play.svg");
    mask-image: url("~images/icons/play.svg");
}

@mixin instagram-icon {
    -webkit-mask-image: url("~images/icons/instagram.svg");
    mask-image: url("~images/icons/instagram.svg");
}
