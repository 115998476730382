.accordion {
  $root: &;

  -webkit-tap-highlight-color: transparent; // sass-lint:disable-line no-vendor-prefixes

  &:active,
  &:hover {
    #{$root}__title {
      color: $color--purple;
      cursor: pointer;
    }
  }

  &[open] {
    #{$root}__icon {
      transform: rotate(90deg);
    }

    #{$root}__title {
      color: $color--purple;
    }
  }

  &__title {
    align-items: center;
    color: $color--navy;
    display: flex;
    font-weight: $weight--bold;
    transition: color 0.2s ease;
  }

  &__icon {
    fill: $color--teal;
    height: 18px;
    margin-right: 20px;
    transition: transform, fill, 0.2s ease;
    width: 18px;
  }

  &__content {
    margin-left: 40px;

    a {
      @include font-size(epsilon);
    }
  }
}
