.react-gallery {
  position: relative;
  margin: 60px 0;

  &__title {
    display: inline-block;
    padding: 8px 40px 8px 20px;
    margin: 0;

    @include media-query(tablet-portrait) {
      padding-left: 40px;
      max-width: 314px;
    }

    @include media-query(tablet-landscape) {
      padding-left: 90px;
      max-width: 470px;
    }

    @include media-query(desktop) {
      padding-left: 150px;
      max-width: 635px;
    }
  }

  &__gallery {
    position: relative;

    &--collapsed {
      max-height: 1000px;
      overflow: hidden;
    }
  }

  &__notch {
    position: absolute;
    background-color: $color--white;
    height: 30px;

    &--top {
      top: 0;
      right: 0;
      width: 60%;
    }

    &--bottom {
      bottom: 0;
      left: 0;
      width: 60%;
      z-index: 5;
    }

    // Lowers z-index of notch when gallery is open.
    &--open {
      z-index: 3 !important;
    }
  }

  &__collapse {
    width: 100%;
    position: absolute;
    bottom: 0;
    text-align: right;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100px;
    font-weight: $weight--normal;

    @include media-query(tablet-portrait) {
      padding: 0 30px;
    }

    @include media-query(tablet-landscape) {
      padding: 0 80px;
    }

    @include media-query(desktop) {
      padding: 0 140px;
    }
  }

  &__collapse-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: none;
    max-width: 635px;
    padding: 10px 30px;
    margin-right: 20px;
    font-family: $font--primary;

    &--black {
      border-top: 2px solid rgba(0, 0, 0, 0.2);
    }

    &:hover {
      cursor: pointer;
      color: $color--black !important;
      background-color: $color--white !important;
      border-top: none;

      .square-icon__icon--gallery-see-more {
        background-color: $color--black !important;
      }
    }
  }

  * {
    transition: $transition;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}
